.AccountMenu {
    .container {
        width: 18rem;
        background-color: $black;
        position: absolute;
        right: 0rem;
        top: 5rem;
        opacity: 0;
        visibility: hidden;
        text-align: left;
        font-size: 1.4rem;
        border-radius: 0.4rem;
        z-index: 100;
        padding: 2rem 0;
        .light-theme & {
            background-color: $white;
            border: $border-grey;
        }
    }

    ul {
        list-style: none;
    }

    .text {
        font-size: 1.6rem;
        &.selected {
            color: $white;
            .light-theme & {
                color: black;
            }
        }
    }
    li {
        color: $night-theme-text;
        padding-left: 3rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        white-space: nowrap;
        &:hover {
            cursor: pointer;
            color: $white;
        }
        .light-theme & {
            color: map-get($light-theme, text-grey);
            &:hover {
                color: map-get($light-theme, black);
            }
        }
    }
}
