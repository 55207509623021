.CardSale {
    &.standard-colors {
        border: 1px solid #343434;
        background: $sidebar-background-grey;

        .light-theme & {
            border: 1px solid #e1e1e1;
            background: $white;
        }
        span {
            color: $primary;
        }
        .membership-text.type {
            .light-theme & {
                color: $black;
            }
        }
        .price-current {
            color: $white;
            .light-theme & {
                color: $black;
            }
        }
        .perMonth {
            color: $white;
            .light-theme & {
                color: $black;
            }
        }
        .subtext {
            color: $white;
            .light-theme & {
                color: $black;
            }
        }
        .description {
            color: $primary;
        }
        .BPM-Button {
            background: $primary !important;
            span {
                color: $white !important;
            }
        }
        &:hover {
            .perMonth {
                color: $black;
            }
            .subtext {
                color: $black;
            }
        }
    }
    @extend .flex-column;
    width: 264px;
    padding: 45px 0;
    border: 1px solid transparent; // prevent moving on hover
    border-radius: 6px;
    background: $primary;
    &:hover {
        background: $white;
        border: 1px solid #e1e1e1;

        .membership-header {
            color: $primary;
        }
        .price-container {
            color: $primary;
        }
        .membership-text {
            &.type {
                color: $black !important;
            }
        }
        .subtext {
            color: rgba($black, 0.5);
        }
        .price-current {
            color: $black;
        }
        .perMonth {
            color: $white;
            .light-theme & {
                color: $white;
            }
        }
        .BPM-Button {
            background: $primary !important;
            span {
                color: $white !important;
            }
        }
    }

    &:hover {
        .light-theme & {
            background: $sidebar-background-grey;
            .membership-header {
                color: $primary;
            }
            .price-container {
                color: $primary;
            }
            .membership-text {
                &.type {
                    color: $white !important;
                }
            }
            .subtext {
                color: $white;
            }
            .price-current {
                color: $white;
            }
            .BPM-Button {
                background: $primary !important;
                span {
                    color: $white !important;
                }
            }
        }
    }
    * {
        font-family: 'Poppins-semi-bold' !important;
    }

    .membership-header {
        @extend .flex-column;
        font-size: 18px;
        line-height: 20px;
        color: #77120a;
        .membership-text {
            &.type {
                color: $white;
            }
        }
    }

    .subtext {
        color: rgba($white, 0.5);
        text-align: center;
        padding: 0 40px;
        @media all and (max-width: $bpm-brk-5) {
            padding: 0 60px;
        }
    }

    .price {
        &-container {
            @extend .flex-column;
            color: #77120a;
            font-size: 16px;
            .perMonth-container {
                display: flex;
                align-items: flex-end;
            }
        }
        &-crossed {
            font-family: 'Poppins' !important;
            text-decoration: line-through;
        }
        &-current {
            color: $white;
            font-size: 28px;
            line-height: 28px;
        }
        &-percent-off {
        }
    }
    .BPM-Button {
        border-radius: 6px;
        border: none !important;
        outline: none !important;
        &:hover {
            border: none !important;
            outline: none !important;
        }
    }
}
