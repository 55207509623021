.contest-background {
    min-height: 100vh;
    padding: 45px 0;
    display: flex;
    justify-content: center;
    margin: 0 -6rem;

    @media all and (max-width: $bpm-brk-5) {
        margin: 0 !important;
        display: unset;
    }
    @media all and (max-width: $bpm-brk-1) {
        margin: 0;
    }
    .Contest {
        @media all and (min-width: $bpm-brk-5) {
            width: 90%;
        }
        @media all and (min-width: $bpm-brk-1) {
            width: calc(100vw * 0.6);
        }
        max-width: 1200px;
        .banner-image {
            width: 100%;
        }
        .contest-title {
            color: $white;
            font-size: 24px;
            margin: 32px 0;
            .light-theme & {
                color: $black;
            }
            @media all and (max-width: 649px) {
                padding: 0 2rem;
            }
        }
        .contest-description {
            p,
            span,
            li {
                color: rgba($white, 0.4) !important;
                .light-theme & {
                    color: $night-theme-text !important;
                }
            }
            @media all and (max-width: 649px) {
                padding: 0 2rem;
            }
        }
        .line {
            height: 1px;
            background: rgba($white, 0.1);
            width: 100%;
            .light-theme & {
                background: rgba($black, 0.1);
            }
        }
        .play-song {
            display: flex;
            align-items: center;
            @media all and (max-width: 649px) {
                padding: 0 2rem;
            }
            .play-btn {
                cursor: pointer;
            }
            .text-content {
                margin-left: 25px;
                .title {
                    color: $white;
                    .light-theme & {
                        color: $black;
                    }
                    font-size: 24px;
                }
                .flex {
                    align-items: center;
                    .artist {
                        color: $white;
                        .light-theme & {
                            color: $night-theme-text;
                        }
                    }
                    .artwork {
                        width: 35px;
                        height: 35px;
                        border-radius: 50%;
                    }
                }
            }
        }
        .no-contest {
            text-align: center;
            margin-bottom: 5rem;
            font-size: 3.4rem;
            color: $white;
            font-family: "Poppins-semi-bold";
            .light-theme & {
                color: $black;
            }
        }
        .contest-section {
            .contest-section-title .container {
                justify-content: start;
            }
            .artwork-container {
                display: flex;
                justify-content: start;
                flex-flow: wrap;
                margin-left: -10px;
                @media all and (max-width: 684px) {
                    justify-content: center;
                }
                .artwork {
                    &:last-child {
                        margin-right: 0;
                    }
                    padding: 10px;
                    border-radius: 14px;
                    cursor: pointer;
                    width: 23%;
                    height: 23%;
                    min-width: 200px;
                }
            }
        }
    }
}
