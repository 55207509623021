.LabelComponent {
    .labels-grid {
        --max-repeat: auto-fill;
        display: grid;
        grid-template-columns: repeat(var(--max-repeat, auto-fill), minmax(38.4rem, 38.4rem));
        grid-gap: 4rem 4rem;
        grid-gap: 58px 58px;
        width: 100%;
        // @media screen and (max-width: $mac-15) {
        grid-template-columns: repeat(var(--max-repeat, auto-fill), minmax(194px, 194px));
        // }
        justify-content: center;
    }
    .card {
        height: 42.3rem;
        width: 40.3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 2rem;
        // @media screen and (max-width: $mac-15) {
        height: 277px;
        width: 194px;
        border-radius: 10px 10px 30px 30px;
        // }
        &:last-child {
            margin-right: 0;
        }
        .image {
            cursor: pointer;
            height: 45%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            // @media screen and (max-width: $mac-15) {
            height: 100%;
            // }
            .logo {
                max-height: 90%;
                max-width: 90%;
                // @media screen and (max-width: $mac-15) {
                max-height: 120%;
                max-width: 120%;
                // }
            }
        }
        .text-content {
            background: #262626;
            border-radius: 0 0 2rem 2rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 55%;
            padding-top: 1.6rem;
            width: 100%;
            padding-left: 1rem;
            padding-right: 1rem;
            // @media screen and (max-width: $mac-15) {
            border-radius: 0 0 10px 10px;
            // }
            .light-theme & {
                background: #1a1a1a;
            }
            .title {
                color: $white;
                font-size: 1.9rem;
                cursor: pointer;
                font-family: 'Poppins-semi-bold';
                text-align: center;
            }
            .packCount {
                font-size: 1.9rem;
                color: $primary;
                margin-bottom: 1rem;
                margin-bottom: 2.3rem;
                cursor: pointer;
                // @media screen and (max-width: $mac-15) {
                font-size: 14px;
                // }
            }
            .tags {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                max-height: 8rem;
                overflow: hidden;
                margin-right: -1rem;
                padding: 0 1rem;
                // @media screen and (max-width: $mac-15) {
                display: none;
                // }
                button {
                    background: transparent;
                    padding: 0.6rem 0.9rem;
                    margin-bottom: 1rem;
                    margin-right: 1rem;
                    font-size: 1.3rem;
                }
            }
            .viewAll {
                cursor: pointer;
                font-size: 1.4rem;
                margin-top: 1.6rem;
                color: $night-theme-text;
                // @media screen and (max-width: $mac-15) {
                display: none;
                // }
            }
        }
    }
    .search-not-found {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2.5rem;
        color: #fff;
        margin-top: 4rem;
        .light-theme & {
            color: $black;
        }
    }
}
