.MuiSlider {
    &-root {
        color: $primary !important;
        .light-theme & {
            color: crimson !important;
        }
    }
    &-rail {
        color: $night-theme-text;
    }
    &-thumb {
        box-shadow: none !important;
    }
}

.keyboard {
    display: flex;
    flex-direction: column;
    background: $black;
    padding: 3rem 2.5rem;
    border-radius: 0.5rem;
    filter: drop-shadow(0 0 1rem rgba(0, 0, 0, 0.3));
    will-change: filter;
    @media all and (max-width: 360px) {
        transform: scale(0.9);
        padding: 3rem 2.5rem;
    }
    @media all and (max-width: 320px) {
        transform: unset;
        padding: 3rem 0.4rem;
        margin-left: -0.3rem;
    }
    .light-theme & {
        background: $white;
    }
    .top-container {
        display: flex;
        justify-content: space-evenly;
        position: relative;
        .flats,
        .sharps,
        .major,
        .minor {
            padding: 0rem 2rem;
            color: $night-theme-text;
            cursor: pointer;
            &:hover {
                color: $primary;
            }
        }
        .underline-highlight {
            position: absolute !important;
            width: 25%;
            top: 3.1rem;
            height: 1px;
            &.flats.selected {
                background: $primary;
                left: 0;
            }
            &.sharps.selected {
                left: 23%;
                background: $primary;
            }
            &.major.selected {
                left: 50%;
                background: $primary;
            }
            &.minor.selected {
                left: 75%;
                background: $primary;
            }
        }
        .flats {
            position: relative;
            &.selected {
                color: $primary;
                &::after {
                    content: '';
                    width: 100%;
                    left: 0;
                    bottom: -20px;
                    height: 10px;
                    position: absolute;
                    z-index: 1300;
                    filter: $crimson-bright-filter; // not working :(
                }
            }
        }
        .sharps {
            &.selected {
                color: $primary;
            }
        }
        .major,
        .minor {
            border-radius: 0.3rem;
            &:hover {
                color: $primary;
            }
            &.selected {
                color: $primary;
            }
        }
    }
    .divider {
        width: 100%;
        height: 1px;
        background: rgba($night-theme-text, 0.5);
        margin: 0.8rem 0 2.3rem 0;
        .light-theme & {
            background: #e1e1e1;
        }
    }
    .slider-container {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
        .slider {
            width: 21.5rem;
        }
        .bpm-text {
            font-size: 1.6rem;
            color: $night-theme-text;
            margin-right: 2rem;
            @media screen and (max-width: $bpm-brk-6) {
                margin-right: 1rem;
            }
        }
        .bpm-min {
            font-size: 1.6rem;
            color: $night-theme-text;
            margin-right: 2rem;
            width: 1.5rem;
        }
        .bpm-max {
            font-size: 1.6rem;
            color: $night-theme-text;
            margin-left: 2rem;
            width: 1.5rem;
        }
    }
    .keyboard-container {
        .keys {
            display: flex;
            cursor: pointer;
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }

        .key {
            position: relative;
            width: 5.14rem;
            border: 1px solid $keyboard-button-outline;
            background: $button-dark-grey;
            height: 7rem;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            margin-bottom: 0rem;
            @media screen and (max-width: $bpm-brk-6) {
                width: 4.8rem;
            }
            .light-theme & {
                background: $white;
                border: 1px solid #e1e1e1;
                span {
                    color: map-get($light-theme, text-grey);
                }
            }
            &:not(:first-child) {
                border-left: none;
            }
            &:first-child {
                border-top-left-radius: 0.3rem;
                border-bottom-left-radius: 0.3rem;
            }
            &:last-child {
                border-top-right-radius: 0.3rem;
                border-bottom-right-radius: 0.3rem;
            }
            .letter {
                font-size: 1.4rem;
                color: $night-theme-text;
                margin-bottom: 0.5rem;
            }

            .minor-key {
                position: absolute;
                display: flex;
                flex-direction: column;
                padding-top: 0.7rem;
                width: 3.5rem;
                align-items: center;
                background: #2e2e2e;
                top: 0;
                z-index: 1;
                height: 3.5rem;
                &:first-child {
                    left: 3rem;
                }
                .minor-text {
                    font-size: 1.2rem;
                    color: $night-theme-text;
                }
                .light-theme & {
                    border-top: none;
                    background: map-get($light-theme, baby-blue);
                }
            }
        }
    }

    .right-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        margin-left: 5rem;
        margin-right: -1rem;
        span,
        label {
            color: $night-theme-text;
            font-size: 1.8rem;
        }
    }
}
