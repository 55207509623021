.Trending {
    &.container {
        overflow: visible;
    }
    .header-text {
        margin-bottom: 4.25rem;

        .title {
            color: $white;
            font-size: 2.4rem;
            font-family: 'Poppins-semi-bold';
            .light-theme & {
                color: $black;
            }
        }
        .view {
            color: $primary;
            font-size: 1.4rem;
            margin-left: 1.8rem;
            cursor: pointer;
            @media screen and (max-width: $bpm-brk-4) {
                display: none;
            }
        }
    }
    .grid {
        overflow: visible;
    }
    ul {
        height: 43rem;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        overflow: visible;
        transition: height 0.5s ease-in-out;
        align-content: space-between;
        @media screen and (max-width: $bpm-brk-4) {
            flex-wrap: unset;
            padding: 0;
        }
        @media screen and (max-width: 500px) {
            height: 100%;
        }
        @media all and (min-width: 500px) and (max-width: 800px) {
            height: unset;
        }
    }
    li {
        @media all and (min-width: 500px) and (max-width: 800px) {
            height: unset;
        }
        display: flex;
        height: 76px;
        align-items: center;
        position: relative;
        box-shadow: 0 0.2rem 0 0 rgba(46, 46, 46, 0.35);
        padding-bottom: 1.6rem;
        padding: 1.4rem 1.7rem 1.4rem 1rem;
        overflow: visible;
        width: 46%;

        &:nth-child(5) {
            box-shadow: none;
        }
        &:last-child {
            box-shadow: none;
        }
        &:hover,
        &.playing {
            &:hover {
                background: #232323;
            }
            .light-theme & {
                background: rgba(map-get($light-theme, off-white-primary), 0.7);
            }
            .number {
                display: none;
            }
            .play-button {
                display: unset;
                cursor: pointer;
            }
        }
        .light-theme & {
            box-shadow: 0 0.1rem 0 0 #e0e4e7;
        }
        .number {
            position: absolute;
            left: 3rem;
            font-size: 1.6rem;
            color: $white;
            &:hover {
                display: none;
            }
            .light-theme & {
                color: map-get($light-theme, $black);
            }
            @media screen and (max-width: 500px) {
                display: none;
            }
        }

        .play-button {
            transform: scale(0.7);
            position: absolute;
            left: 1.6rem;
            display: none;
            width: 3.7rem;
            cursor: pointer;
            @media all and (max-width: 500px) {
                transform: scale(0.9);
                left: 0.7rem;
                top: 2.2rem;
            }
        }
        .triangle {
            &.up {
                filter: $crimson-bright-filter;
                transform: rotate(180deg);
            }
            margin-right: 2rem;
            margin-left: 5rem;
            @media screen and (max-width: 500px) {
                display: none;
            }
        }
        .image-container {
            cursor: pointer;
        }
        .image {
            margin-right: 2.5rem;
            border-radius: 0.7rem;
            width: 6.5rem;
            @media screen and (max-width: $bpm-brk-4) {
                width: 3.5rem;
            }
            @media screen and (max-width: 500px) {
                margin-right: 1.5rem;
                width: 5.5rem;
            }
        }
        .content-container {
            width: 100%;
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 0;
            min-width: 0;
        }
        .text-container {
            .title {
                font-size: 1.6rem;
                margin-right: 2.5rem;
                color: $white;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                .light-theme & {
                    color: map-get($light-theme, $black);
                }
                @media screen and (max-width: 500px) {
                    font-size: 14px;
                }
            }
            .description {
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                max-height: 4rem;
                overflow: hidden;
                text-overflow: ellipsis;

                font-size: 12px;
                margin-top: 0.5rem;
                color: map-get($light-theme, text-grey);
                @media screen and (max-width: 500px) {
                    font-size: 14px;
                }
            }
        }

        .ellipses {
            color: $primary;
            width: 5.1rem;
            padding: 2rem;
            padding-right: 1rem;
            margin-left: auto;
            justify-content: flex-end;
            right: 2.4rem;
            cursor: pointer;
            @media screen and (max-width: 500px) {
                padding: 1rem;
                width: 4.5rem;
            }
        }
        @media screen and (max-width: $bpm-brk-4) {
            width: 100%;
            max-width: unset;
        }
        @media screen and (max-width: 500px) {
            padding: 4.4rem 0;
            min-width: unset;
        }
    }
}

@media screen and (max-width: 1500px) {
    .Trending {
        .li {
            margin-right: 2rem;
        }
        .ul {
            margin-right: 10vw;
        }
    }
}

@media screen and (max-width: 1400px) {
    .Trending {
        .ul {
            height: 100%;
            display: block;
            flex-direction: column;
            flex-wrap: unset;
            align-content: flex-start;
            overflow: visible;
        }
    }
}
