.payment-method__container {
    display: flex;
    flex-direction: column;
    .payment-method__item {
        margin-right: 6rem;
        margin-bottom: 4px;
        &.centered {
            text-align: center;
        }
    }

    .payment-method__item:last-child {
        margin-right: 0;
    }

    .payment-method__item input[type='radio'] {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }

    .payment-method__item input[type='radio'] + label {
        position: relative;
        padding-left: 2rem;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 1.6rem;
        text-transform: uppercase;
        cursor: pointer;
    }

    .payment-method__item input[type='radio'] + label::before {
        content: '';
        position: absolute;
        top: 3px;
        left: 0;
        width: 14px;
        height: 14px;
        background-color: transparent;
        border: 0.2rem solid #5e5e5e;
        border-radius: 50%;
        -webkit-transition: all 200ms ease;
        -o-transition: all 200ms ease;
        -moz-transition: all 200ms ease;
        transition: all 200ms ease;
    }

    .payment-method__item input[type='radio'] + label::after {
        content: '';
        position: absolute;
        top: 6px;
        left: 3px;
        width: 8px;
        height: 8px;
        background-color: $primary;
        border-radius: 50%;
        opacity: 0;
        -webkit-transition: all 200ms ease;
        -o-transition: all 200ms ease;
        -moz-transition: all 200ms ease;
        transition: all 200ms ease;
    }

    .payment-method__item input[type='radio']:checked + label::before {
        border-color: $primary;
    }

    .payment-method__item input[type='radio']:checked + label::after {
        opacity: 1;
    }

    .payment-method__item input[type='radio']:focus + label::before {
        -webkit-box-shadow: 0 0 0 0.1rem darken($primary, 20%);
        box-shadow: 0 0 0 0.1rem darken($primary, 20%);
    }
}