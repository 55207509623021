#loader {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: $primary;
    -webkit-animation: spin 0.45s linear infinite;
    animation: spin 0.45s linear infinite;
    &.white {
        border-top-color: $white;
    }
    .large & {
        -webkit-animation: spin 1s linear infinite;
        animation: spin 1s linear infinite;
    }
}
#loader:before {
    content: '';
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: $white;
    -webkit-animation: spin 2.5s linear infinite;
    animation: spin 2.5s linear infinite;
    display: none;
    .large & {
        -webkit-animation: spin 3s linear infinite;
        animation: spin 3s linear infinite;
        display: unset;
    }
}
#loader:after {
    content: '';
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    right: 1.5rem;
    bottom: 1.5rem;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: $primary;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    display: none;
    .large & {
        -webkit-animation: spin 1.5s linear infinite;
        animation: spin 1.5s linear infinite;
        display: unset;
    }
}
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
