.FAQ {
    display: flex;
    justify-content: center;
    background: $background-light-grey;
    width: 100%;
    padding: 15rem 0 15rem 0;
    strong {
        font-weight: 400;
        color: $white;
        .light-theme & {
            color: $black;
            font-weight: bold;
        }
    }
    .space {
        height: 26px;
        display: block;
    }
    .link {
        color: $primary;
        text-decoration: underline;
        cursor: pointer;
    }
    .light-theme & {
        background: map-get($light-theme, off-white-primary);
    }
    .centered {
        max-width: 830px;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media all and (min-width: $bpm-brk-3) {
            transform: scale(1.1);
        }
        .header {
            color: $white;
            font-size: 2.4rem;
            font-family: 'Poppins-semi-bold';
            margin-bottom: 5rem;
            .light-theme & {
                color: $black;
            }
        }
        #first {
            border-top: unset !important;
        }
        .faq-container {
            width: 100%;
            height: 8rem;
            overflow: hidden;
            border-top: 1px solid #343434;
            @media all and (max-width: $bpm-brk-4) {
                width: 90%;
            }
            .light-theme & {
                border-top: 1px solid #e5e5e5;
            }
            .title-container {
                display: flex;
                justify-content: space-between;
                cursor: pointer;
                font-size: 13px;
                height: 8rem;
                display: flex;
                align-items: center;

                .title {
                    color: $white;
                    .light-theme & {
                        color: $black;
                        font-weight: bold;
                    }
                }
                .chevron {
                    height: 1.4rem;
                    width: auto;
                    filter: invert(39%) sepia(0%) saturate(2607%) hue-rotate(231deg) brightness(80%) contrast(93%);
                    transform: rotate(-90deg);
                    margin-right: 2rem;
                    .light-theme & {
                        filter: invert(84%) sepia(0%) saturate(19%) hue-rotate(134deg) brightness(101%) contrast(85%);
                    }
                    @media all and (max-width: $bpm-brk-3) {
                        height: 1.2rem;
                    }
                }
            }

            p {
                color: #8c8c8c;
                opacity: 0;
                margin-bottom: 1rem;
            }
        }
    }
}
