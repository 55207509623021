.HamburgerMenu {
    width: 100%;
    background: $black;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.15s;
    .light-theme & {
        background: $white;
    }

    &.open {
        max-height: 75rem;
        transition: max-height 0.5s;
    }
    @media screen and (max-width: $bpm-brk-6) {
        padding: 0 1.5rem;
    }
    .divider {
        height: 1px;
        width: 100%;
        background: $search-grey;
        &:first-child {
            display: none;
        }
    }
    .container {
        display: flex;
        justify-content: center;
        padding: 1rem 2.6rem 6rem 2.6rem;
        .light-theme & {
            background: $white;
        }
    }
    .menus {
        width: 100%;
        background: $black;
        max-width: 40rem;
        cursor: pointer;

        .light-theme & {
            background: $white;
        }

        .category-wrapper:first-child {
            margin-top: 68px;
        }
        .category {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 1.8rem;
            padding: 2rem 1rem;
            padding-bottom: 1.2rem;

            .text {
                color: white;
                .light-theme & {
                    color: $black;
                }
            }
            .chevron {
                filter: $white-filter;
                transition: transform 0.4s;
                .light-theme & {
                    filter: $black-filter;
                }
                &.selected {
                    transform: rotate(-180deg);
                    transition: transform 0.4s;
                }
            }
        }
        ul {
            transition: height 0.3s;
            overflow: hidden;
            &.selected {
                transition: height 0.3s;
            }
            &.closed {
                transition: height none !important;
            }
            li {
                padding: 1rem 1rem;

                &:first-child {
                    // padding-top: 0;
                }
                &:hover {
                    .text {
                        color: $white;
                        .light-theme & {
                            color: $black;
                        }
                    }
                    .chevron {
                        filter: $black-filter;
                    }
                }
                .text {
                    color: $night-theme-text;
                    font-size: 1.8rem;
                }
            }
        }
    }
}
