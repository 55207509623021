.search-container {
    .search-bar-container {
        .react-autosuggest__container:not(.react-autosuggest__container--open) {
            display: hidden;
        }
        .flex-container {
            display: flex;
            flex-direction: column;
            padding: 4rem;
        }
        .attributes-header {
            color: $night-theme-text;
            margin-left: 1rem;
            font-size: 1.7rem;
            margin-bottom: 0.5rem;
        }
        .suggestion {
            background: #222222;
            border: 1px solid rgba(110, 110, 110, 0.34);
            padding: 0.3rem 2rem;
            border-radius: 5rem;
            color: $night-theme-text;
            font-size: 1.3rem;
            &:hover {
                color: $white;
            }
        }
        .react-autosuggest__suggestions-list {
            display: flex;
            flex-wrap: wrap;
        }
    }
}
