.AccountFooter {
    display: grid;
    padding: 32px 10rem;
    grid-template-columns: repeat(3, 33.3333%);
    background: $black;
    @media all and (max-width: 1112px) {
        padding: 32px 5rem;
    }
    @media all and (max-width: $bpm-brk-4) {
        padding: 32px 2rem;
    }
    @media all and (max-width: $bpm-brk-4) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
    }
    .light-theme & {
        background: $white;
    }
    .left {
        display: flex;
        color: #8c8c8c;
        align-items: center;
        font-size: 1.4rem;
        @media all and (max-width: $bpm-brk-3) {
            flex-direction: column;
            align-items: flex-start;
            span {
                display: none;
            }
        }
        @media all and (max-width: $bpm-brk-4) {
            align-items: center;
            justify-content: center;
        }
        .logo {
            width: 14rem;
            margin-right: 2rem;
            @media all and (max-width: $bpm-brk-3) {
                margin-bottom: 1rem;
            }
        }
    }
    .middle {
        display: flex;
        justify-content: center;
        align-items: center;
        .f-social__item {
            padding: 1rem 2rem;
            margin-bottom: -1rem;
            @media all and (max-width: $bpm-brk-3) {
                padding: 1rem 1rem;
            }
            img {
                height: 18px;
                width: auto;
            }

            filter: brightness(0) saturate(100%) invert(51%) sepia(9%) saturate(0%) hue-rotate(287deg) brightness(107%)
                contrast(97%);
            &:hover {
                filter: $crimson-bright-filter;
            }
        }
    }
    .right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: #8c8c8c;
        font-size: 1.4rem;
        @media all and (max-width: $bpm-brk-4) {
            flex-direction: column;
        }
        .divider {
            margin: 0 1rem;
        }
        .copy {
            strong {
                color: #bfbfbf;
            }
            @media all and (min-width: $bpm-brk-4) {
                display: none;
            }
        }
        .terms-conditions {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .terms {
                &:hover {
                    color: $primary;
                }
            }
            .privacy {
                &:hover {
                    color: $primary;
                }
            }
        }
    }
}
