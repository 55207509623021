* {
    font-family: 'Poppins';
}

#root {
    overflow-y: visible;
}

html {
    width: 100%;
    height: 100%;
}

body {
    // background-color: $background-light-grey;
    background-color: $sidebar-background-grey;
    position: relative;
    &-light-theme {
        background-color: $white;
    }
}

.main-content {
    transition: margin $sidebar-transition-duration;
    margin-left: #{$sidebar-collapsed-width + 6rem};
    margin-right: 6rem;
    padding-bottom: 15rem;
    padding-top: 68px !important;

    @media all and (min-width: $max-width) {
        display: flex;
        justify-content: center;
        .constrain-width {
            max-width: 1494px;
            width: 100%;
        }
    }
}

.push-main-content {
    + .main-content {
        margin-left: #{$sidebar-width + 6rem};
    }
}

@media screen and (max-width: $bpm-brk-3) {
    .main-content {
        margin: 0;
    }
    .push-main-content {
        + .main-content {
            margin: 0 2rem;
        }
    }
}
@media screen and (max-width: $bpm-brk-5) {
    .push-main-content {
        + .main-content {
            margin: 0 1rem;
        }
    }
}

.MuiPaper-root,
.MuiPaper-elevation8 {
    box-shadow: unset !important;
}

#jsd-widget {
    bottom: 60px !important;
    right: 1% !important;
    z-index: 999 !important;
    @media screen and (max-width: $bpm-brk-4) {
        display: none !important;
    }
}

#confirmation-container {
    z-index: 999999 !important;
}

.display-none {
    display: none !important;
}

[contenteditable] {
    -webkit-user-select: text;
    user-select: text;
}

// Reference for vertical centering
// body {
//     position: relative;
//     &:after {
//         content: '';
//         position: absolute;
//         left: 50%;
//         height: 500rem;
//         overflow: show;
//         width: 5px;
//         background: pink;
//         z-index: 10000;
//     }
// }
