.share-container {
    .share-box {
        background: $black;
        padding: 4rem;
        border-radius: 0.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .light-theme & {
            background: $white;
            filter: drop-shadow(0 0 1rem rgba(0, 0, 0, 0.3));
            will-change: filter;
        }
        .share-row {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            .share-item {
                display: flex;
                flex-direction: column;
                color: $white;
                align-items: center;
                width: 8.5rem;
                margin-bottom: 1rem;
                .light-theme & {
                    color: $black;
                }
                svg {
                    margin-bottom: 0.6rem;
                }
            }
        }
        .share-input {
            display: flex;
            align-items: center;
            height: 4rem;
            margin-top: 1rem;
            input {
                cursor: text;
                height: 100%;
                width: 70%;
                font-size: 1.4rem;
                background: #222222;
                color: $white;
                padding: 0 0.5rem 0 1.5rem;
                border: none;
                outline: none;
                border-top-left-radius: 0.3rem;
                border-bottom-left-radius: 0.3rem;
                .light-theme & {
                    background: rgba(234, 234, 234, 0.5);
                    color: #555555;
                }
            }
        }
        .copy-button {
            width: 30%;
            background: #222222;
            color: $primary;
            font-size: 1.4rem;
            height: 100%;
            border: none;
            font-family: 'Poppins-bold';
            outline: none;
            border-top-right-radius: 0.3rem;
            border-bottom-right-radius: 0.3rem;
            .light-theme & {
                background: rgba(234, 234, 234, 0.5);
                color: black;
                font-family: 'Poppins';
            }
        }
    }
}
