.SubmissionCards {
    display: flex;
    flex-direction: column;

    .card {
        background: #1a1a1a;
        margin-bottom: 20px;
        border-radius: 3px;

        .light-theme & {
            background: transparent;
            border: 1px solid #e1e1e1;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .top-container {
        display: flex;
        align-items: center;
        padding: 5px 35px;

        .light-theme & {
            background: #fcfcfc;
        }

        @media all and (max-width: 835px) {
            padding: 15px;
        }

        .play {
            margin-right: 20px;
            cursor: pointer;
        }

        .wav {
            width: 372px;
            height: 60px;
            padding-top: 10px;
            padding-bottom: 10px;

            @media all and (max-width: 835px) {
                display: none;
            }
        }

        .title {
            color: $white;
            margin: 0 20px;
            margin-right: auto;

            .light-theme & {
                color: $black;
            }

            font-size: 15px;

            @media all and (max-width: 835px) {
                font-size: 14px;
                margin-left: 0;
            }
        }

        .count-wrapper {
            display: flex;
            margin-left: 20px;

            @media all and (max-width: 414px) {
                display: none;
            }

            .play-count {
                margin-top: 2px;
                margin-right: 12px;
                @extend .no-select;
                cursor: default;
                display: flex;
                align-items: center;

                img {
                    width: 16px;
                    height: 16px;
                    margin-right: 4px;

                    filter: brightness(0) saturate(70%) invert(51%) sepia(14%) saturate(0%) hue-rotate(151deg) brightness(50%) contrast(93%);

                    .light-theme & {
                        filter: brightness(0) saturate(100%) invert(51%) sepia(14%) saturate(0%) hue-rotate(151deg) brightness(130%) contrast(93%);
                    }
                }

                span {
                    color: rgba(#fff, 0.25);

                    .light-theme & {
                        color: rgba(#000, 0.4);
                    }
                }
            }

            .heart-count {
                margin-top: 2px;
                margin-right: 12px;
                @extend .no-select;
                cursor: default;
                display: flex;
                align-items: center;

                img {
                    width: 20px;
                    height: 20px;
                    margin-right: 4px;
                    filter: brightness(0) saturate(70%) invert(51%) sepia(14%) saturate(0%) hue-rotate(151deg) brightness(50%) contrast(93%);

                    .light-theme & {
                        filter: brightness(0) saturate(100%) invert(51%) sepia(14%) saturate(0%) hue-rotate(151deg) brightness(130%) contrast(93%);
                    }
                }

                span {
                    color: rgba(#fff, 0.25);

                    .light-theme & {
                        color: rgba(#000, 0.4);
                    }
                }
            }
        }

        .buttons {
            cursor: pointer;
            display: flex;

            @media all and (max-width: 414px) {
                flex-direction: column;
            }

            .heart {
                margin-right: 12px;

                &:hover {
                    filter: $crimson-bright-filter  !important;
                }

                &:not(.remove-fill) {
                    filter: $white-filter;

                    .light-theme & {
                        filter: $night-theme-text-filter;
                    }
                }

                @media all and (max-width: 414px) {
                    margin-right: 0;
                }

                @media all and (max-width: 835px) {
                    width: 22px;
                    width: 22px;
                    height: 24px;
                    margin: 4px;
                }
            }

            .share {
                filter: $white-filter;

                &:hover {
                    filter: $crimson-bright-filter  !important;
                }

                .light-theme & {
                    filter: $night-theme-text-filter;
                }

                @media all and (max-width: 835px) {
                    width: 22px;
                    width: 24px;
                    height: 24px;
                    margin: 4px;
                }
            }
        }
    }

    .bottom-container {
        border-top: 1px solid rgba(#fff, 0.1);

        .light-theme & {
            border-top: 1px solid #e1e1e1;
        }

        padding: 15px 35px;
        display: flex;
        align-items: flex-start;

        @media all and (max-width: 835px) {
            padding: 15px;
        }

        .text {
            flex: 1;
        }

        .image {
            flex: 0 0 30px;
            width: 30px;
            height: 30px;
            margin-right: 20px;
            border-radius: 50%;
        }

        .username {
            color: $white;

            .light-theme & {
                color: $black;
            }

            margin-right: 10px;
        }

        .date {
            color: rgba(#fff, 0.2);

            .light-theme & {
                color: rgba($black, 0.4);
            }

            @media all and (max-width: 414px) {
                display: inline-block;
            }
        }

        .description {
            margin-top: 4px;
            color: rgba(#fff, 0.4);

            .light-theme & {
                color: $night-theme-text;
            }
        }
    }

    .end-of-comments {
        color: white;
        display: flex;
        justify-content: center;
        font-size: 16px;
        transition: all 1s;

        .light-theme & {
            color: black;
        }

        &:not(.show) {
            height: 0;
            transition: all 1s;
            opacity: 0;
            pointer-events: none;
        }
    }

    .view-more {
        display: inline-block;
        padding: 5px;
        margin-top: 10px;
        color: #1af085;
        cursor: pointer;
        @extend .no-select;

        &.end {
            pointer-events: none;
            transition: all 500ms;
            color: rgba($primary, 0);
        }
    }
}