.ButtonPanel {
    .background {
        background-color: $background-light-grey;
        padding-left: 2rem;
        width: 100%;
    }

    .title-container {
        font-family: Calibri;
        padding: 7rem 0 2.6rem 0rem;
    }

    .title {
        color: $white;
        font-size: 2.2rem;
        padding-top: 0.4rem;
        &.link {
            cursor: pointer;
        }
        .light-theme & {
            color: map-get($light-theme, text-grey);
        }
    }

    .viewAll {
        margin-left: 1.8rem;
        color: $primary;
        font-size: 1.4rem;
        margin-top: 1.2rem;
        &:hover {
            cursor: pointer;
        }
    }
    .grid {
        margin-top: 1rem;
        margin-left: 0.2rem;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(7.8rem, 24rem));
        max-width: 100%;
        grid-row-gap: 2.4rem;
        grid-column-gap: 2.4rem;
        @media all and (min-width: $max-width) {
            grid-template-columns: repeat(6, minmax(7.8rem, 24rem));
        }
    }
    .rah-static.rah-static--height-specific.AnimateHeight {
        @media all and (min-width: $max-width) {
            width: 101%;
        }
    }
    .grid-child {
        outline: $border-grey;
        padding: 2.6rem;
        color: $button-text-grey;
        background-color: $button-dark-grey;
        font-family: Calibri;
        font-size: 1.6rem;
        text-align: center;
        white-space: nowrap;
        &:active, &.selected {
            background-color: $primary;
        }
        &:hover {
            background: $primary !important;
            outline: $button-highlight-grey;
            cursor: pointer;
            color: $white !important;
        }

        .light-theme & {
            background: map-get($light-theme, white);
            color: $night-theme-text;
            border: map-get($light-theme, light-grey-border);
        }
    }
}

.flex {
    display: flex;
}
