.SoundsList {
    --drag-preview-width: 5rem;
    // Main content, body
    .end-text {
        display: flex;
        justify-content: center;
        margin-top: 4rem;
        font-size: 16px;
        color: $white;
        .light-theme & {
            color: $black;
        }
    }
    .no-packs {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2.5rem;
        color: #fff;
        margin-top: 4rem;
        .light-theme & {
            color: map-get($light-theme, $black);
        }
    }
    .similar-sounds {
        ul {
            margin-left: 4px;
            .list-item-container:first-child {
                margin-top: 0.5rem;
                border-top: 1px solid rgba(194, 194, 194, 0.2);
                &:hover {
                    border-top: 1px solid transparent;
                }
            }
        }
        background-color: rgba(1, 1, 1, 0.7);
        .light-theme & {
            background: #f6f6f6;
        }
        margin-bottom: 2.5rem;

        .similar-sounds-description {
            text-align: center;
            line-height: 4rem;
            font-weight: 400;
            font-size: 1.8rem;
            padding: 2rem 0;
            color: white;
            .light-theme & {
                color: map-get($map: $light-theme, $key: black);
            }
        }
        .no-similar {
            display: flex;
            justify-content: center;
            font-size: 2.4rem;
            padding: 2rem;
            color: $white;
            .light-theme & {
                color: $black;
            }
        }
    }
    .allow-hover {
        .list-item:not(.header):hover {
            background: #232323;
            border-bottom: 1px solid transparent;
            .light-theme & {
                border-bottom: 1px solid transparent;
                border-radius: 0.2rem;
                background: rgba(map-get($light-theme, off-white-primary), 0.7);
            }
        }
    }
    .item-drag-layer {
        position: absolute;
        width: var(--drag-preview-width);
        height: calc(var(--drag-preview-width));
        background-color: #000000df;
        -webkit-box-shadow: -2px 3px 7px -3px hsl(0, 0%, 0%);
        box-shadow: -2px 3px 7px -3px #000000;
        .light-theme & {
            background-color: #ffffffdf;
        }
        .artwork-container,
        .artwork-container .artwork {
            width: var(--drag-preview-width);
            height: var(--drag-preview-width);
        }
        .name {
            padding: 2rem 1rem;
            min-width: 0;
            margin: 0;
            text-align: center;
            width: auto;
        }
        .badge {
            background-color: $primary;
            float: right;
            position: absolute;
            top: -2rem;
            width: 3rem;
            height: 3rem;
            right: -2rem;
            text-align: center;
            border-radius: 1.5rem;
            line-height: 3rem;
            color: white;
            z-index: 1090;
            font-size: 1.3rem;
        }
    }
    .list-item {
        height: 6rem;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba(194, 194, 194, 0.2);
        margin-bottom: -2px;
        padding: 0.4rem 2rem;
        padding-top: 0.6rem;
        padding-left: 2rem;
        @media all and (max-width: $bpm-brk-4) {
            padding: 0.5rem 1rem;
        }
        @media all and (max-width: $bpm-brk-6) {
            padding: 0.5rem 0rem;
        }
        @media all and (max-width: $bpm-brk-6) {
            height: 7rem;
        }

        &:not(.header) {
            .name {
                padding: 1.5rem 0;
                cursor: pointer;
                @media all and (max-width: bpm-brk-6) {
                    width: 55px !important;
                }
            }
        }
        &.header {
            max-height: 1rem;
            border-bottom: transparent;
            margin-bottom: 1rem;
            @media all and (max-width: $bpm-brk-4) {
                display: none;
            }
            .artwork {
                color: $night-theme-text;
                font-size: 1.3rem;
            }
            .button-container {
                color: $night-theme-text;
                font-size: 1.3rem;
            }
            .name {
                color: $night-theme-text;
            }
            .bpm {
                cursor: pointer;
                .bpm-down {
                    margin-left: 0.6rem;
                    width: 1rem;
                    margin-bottom: 0.3rem;
                }
                .bpm-up {
                    @extend .bpm-down;
                    transform: rotate(180deg);
                }
            }
        }
        &.sound-item-playing {
            background: #232323;
            border-bottom: 1px solid transparent;
            &:before {
                position: absolute;
                content: '';
                background: $primary;
                height: 100%;
                width: 4px;
                left: 0;
            }
            .light-theme & {
                border-bottom: 1px solid transparent;
                border-radius: 0.2rem;
                background: rgba(map-get($light-theme, off-white-primary), 0.7);
            }
        }
    }
    .list-item-container:first-child {
        margin-top: 2.5rem;
    }
    .checkbox-svg {
        &.hidden {
            visibility: hidden;
        }
    }
    .checkbox {
        position: relative;
        width: 2rem;
        height: 2rem;
        margin-right: 2rem;
        @media all and (max-width: $bpm-brk-4) {
            display: none;
        }
        #box {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            & + label:before {
                cursor: pointer;
                left: 0.3rem;
                content: '';
                position: absolute;
                top: 0.1rem;
                transform: translateY(-50%);
                width: 1.7rem;
                height: 1.7rem;
                border-radius: 0.2rem;
                background-color: transparent;
                margin-top: 1rem;
            }
            &:not(:checked) {
                & + label:before {
                    border: 1px solid rgba($night-theme-text, 0.5);
                }
            }
        }
        .text {
            text-align: left;
            font-size: 1.6rem;
            cursor: pointer;
        }
    }
    .artwork-container {
        position: relative;
        width: 5rem;
        height: 5rem;
        @media all and (max-width: $bpm-brk-4) {
            display: none;
        }
        .artwork {
            cursor: pointer;
            width: 5rem;
            height: 5rem;
        }
        &:not(:hover) {
            .Tooltip {
                display: none;
            }
        }
        .Tooltip {
            position: absolute;
            top: unset;
            bottom: -3.5rem;
            left: 0rem;
            right: unset;
        }
    }
    .play-container {
        width: 57px;
        padding: 1.2rem 2rem;
        cursor: pointer;

        .play-button {
            display: flex;
            align-items: center;
            @media all and (max-width: $bpm-brk-6) {
                width: 2.4rem;
            }
        }
    }
    .wave-form-container {
        flex: 5;
        margin-right: 2rem;
        height: 4.5rem;
        cursor: pointer;
        max-width: 31.8rem;
        @media all and (max-width: $bpm-brk-4) {
            display: none;
        }
    }
    .button-container {
        display: flex;
        min-width: 20.3rem;
        flex: 8;
        max-height: 4.2rem;
        overflow: hidden;
        @media all and (max-width: $bpm-brk-2) {
            display: none;
        }
        @media all and (max-width: $bpm-brk-3) {
            display: unset;
        }
        @media all and (max-width: 1023px) {
            display: none;
        }
        .TagsButtons-grid {
            margin-top: 0;
            margin-bottom: 1.2rem;
            max-width: 40rem;
            .BPM-Button.small {
                font-size: 10px;
                padding: 0.25rem 0.5rem;
                margin-right: 0.5rem;
                img {
                    filter: $crimson-bright-filter;
                }
            }
        }
        &.editing {
            .BPM-Button img {
                margin-right: 0.8rem;
                filter: brightness(0) saturate(100%) invert(15%) sepia(94%) saturate(6041%) hue-rotate(326deg)
                    brightness(85%) contrast(135%);
            }
        }
    }
    .name {
        min-width: 9rem;
        width: 23rem;
        flex: 3;
        margin-right: 2rem;
        color: $white;
        @media all and (max-width: $bpm-brk-6) {
            width: 55px !important;
        }
        .name-inner {
            font-size: 1.3rem;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            display: -webkit-box;
            max-width: 200px;
            @media all and (max-width: $bpm-brk-6) {
                -webkit-line-clamp: 3;
            }
        }
        // Experimenting

        @media all and (max-width: $bpm-brk-4) {
            flex: 5;
            min-width: unset;
            width: unset;
        }
        .light-theme & {
            color: map-get($light-theme, black);
        }
    }
    .key-bpm-time-container {
        flex: 2.5;
        display: grid;
        grid-template-columns: 50px 70px 50px;
        color: $night-theme-text;
        text-align: center;
        @media all and (max-width: $bpm-brk-4) {
            grid-template-columns: unset;
            flex: 2;
        }
        .light-theme & {
            color: $night-theme-text;
        }

        .link {
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
        .key {
            font-size: 1.4rem;
            @media all and (max-width: $bpm-brk-4) {
                display: none;
            }
        }
        .bpm {
            font-size: 1.4rem;
            @media all and (max-width: $bpm-brk-4) {
                display: none;
            }
        }
        .time {
            font-size: 1.4rem;
        }
    }
    .icons-container {
        cursor: pointer;
        width: 18rem;
        margin-left: 3rem;
        display: flex;
        align-items: center;
        margin-top: 0.5rem;
        @media all and (max-width: $bpm-brk-4) {
            width: unset;
            margin-left: unset;
        }
        @media all and (max-width: $bpm-brk-4) {
            transform: scale(0.85);
        }
        img:not(.no-fill) {
            filter: $white-filter;
            &:hover {
                filter: $crimson-bright-filter;
            }
            .light-theme & {
                // Grey color
                filter: brightness(0) saturate(100%) invert(81%) sepia(11%) saturate(10%) hue-rotate(334deg)
                    brightness(95%) contrast(88%);
                &:hover {
                    filter: $crimson-bright-filter;
                }
            }
        }
        img.selected {
            filter: $crimson-bright-filter !important;
        }

        // icons
        // img:not(:last-child) {
        //     margin-right: 1rem;
        // }
    }
    .midi-icon {
        height: 1.7rem;
        margin-top: 0.3rem;
        margin-right: 0.8rem;
        &.hidden {
            visibility: hidden;
            + .Tooltip-midi {
                display: none !important;
            }
        }
    }
    .rings-container {
        position: relative;
        margin-top: 0.2rem;
        margin-right: 0.8rem;
        margin-left: 0.2rem;
        &:not(:hover) {
            .Tooltip {
                display: none;
            }
        }
        .rings-icon {
            width: 2.8rem;
            margin-top: 0.1rem;
        }
    }
    .heart-container {
        position: relative;

        &:not(:hover) {
            .Tooltip {
                display: none;
            }
        }
    }
    .heart-icon {
        width: 2.3rem;
        margin-top: 0.2rem;
    }
    .download-container {
        position: relative;
        margin-left: 0.8rem;
        &.hidden {
            visibility: hidden;
        }
        &:not(:hover) {
            .Tooltip {
                display: none;
            }
        }
        .Tooltip-midi {
            @extend .Tooltip;
            padding: 1.2rem !important;
            .midi-pop-icon {
                margin-right: 1.2rem;
            }
            .divider {
                width: 100%;
                margin-right: 0.6rem;
                background: #990038;
                height: 1px;
                margin: 0.7rem 0 0.9rem 0;
            }
            img {
                height: 1.9rem;
                margin-top: 0.3rem;
            }
            div:not(.text) {
                font-size: 1rem;
            }
            .text {
                white-space: nowrap;
                font-size: 1.4rem;
                margin: 0 !important;
            }
        }
    }
    .download-icon {
        height: 2.3rem !important;
        margin-top: 0.3rem;
    }
    .elipses {
        font-size: 3rem;
        font-weight: bold;
        color: $primary;
        margin-top: -1.7rem;
        margin-left: auto;

        @media all and (max-width: $bpm-brk-4) {
            margin-left: 1.5rem;
        }
        &:hover {
            cursor: pointer;
        }
    }
    .pagination {
        margin-top: 6rem;
        .disabled {
            color: $night-theme-text !important;
            filter: $night-theme-text-filter !important;
            cursor: default !important;
        }
        display: flex;
        justify-content: center;
        align-items: center;
        li {
            padding: 1rem 1.2rem;
            font-size: 1.4rem;
            cursor: pointer;
        }
        .prev {
            color: white;
            padding-left: 1rem;
            font-size: 1.3rem;
            .light-theme & {
                color: $black;
                filter: $black-filter;
            }
            &-chevron {
                @extend .prev;
                width: 1.2rem;
                padding: 0;
                filter: $white-filter;
            }
        }
        .number {
            color: $night-theme-text;
            &:hover {
                color: $white;
                .light-theme & {
                    color: $black;
                }
            }
        }
        .more {
            filter: $night-theme-text-filter;
            margin-bottom: 0.7rem;
            letter-spacing: 0.3rem;
            cursor: default;
            img {
                width: 1.8rem;
            }
        }
        .selected {
            @extend .number;
            color: $white;
            padding: 0.3rem 1.1rem;
            border-radius: 0.3rem;
            background: $primary;
            &:hover {
                color: $white !important;
            }
        }
        .next {
            color: $white;
            filter: $white-filter;
            padding-right: 1rem;
            font-size: 1.3rem;
            .light-theme & {
                color: $black;
                filter: $black-filter;
            }
            &-chevron {
                @extend .next;
                width: 1.2rem;
                padding: 0;
                margin-bottom: 0.4rem;
                transform: rotate(180deg);
            }
        }
    }
    .go-to {
        display: flex;
        justify-content: center;
        margin-top: 1.7rem;
        align-items: center;
        margin-left: 2rem;
        .go-text {
            color: #767676;
            margin-right: 1rem;
        }

        .go-input {
            color: $white;

            // padding: 0.3rem 3rem 0rem 5rem;
            padding: 0 0.8rem;
            padding-left: 1.5rem;
            box-sizing: border-box;
            background-image: none;
            background-color: transparent;
            -webkit-appearance: none;
            box-shadow: none;
            border: none;
            height: 3rem;
            width: 7rem;
            font-size: 1.5rem;
            border-radius: 0.3rem;
            border: 1px solid rgba(194, 194, 194, 0.35);
            .light-theme & {
                color: #767676;
            }
        }
    }
    .tag-key {
        color: $night-theme-text;
        text-transform: capitalize;
        font-weight: 600;
        &:not(:first-child) {
            display: none;
        }
        .light-theme & {
            color: map-get($light-theme, $black);
        }
    }
    .react-tagsinput-tag {
        white-space: nowrap;
        color: $night-theme-text;
        display: inline-block;
        font-weight: 400;
        margin-top: 0;
        margin-right: 1rem;
        padding: 0;
        cursor: pointer;

        .tag-name {
            padding: 0.2rem 1rem;
            border: 1px solid rgba($night-theme-text, 0.5);
            border-radius: 0.3rem;
            margin-left: 4px;
            &:hover {
                background: $primary;
                color: $white;
                border: 1px solid transparent;
            }
        }

        .light-theme & {
            color: $night-theme-text;
        }
    }
    .react-tagsinput-remove {
        cursor: pointer;
        font-weight: bold;
    }
    .react-tagsinput-tag a::before {
        content: ' ×';
    }
}
.SoundListHeader {
    margin-top: -1rem;
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .header-left {
        color: $white;
        .light-theme & {
            color: map-get($light-theme, black);
        }
        .title {
            font-size: 2.2rem;
            margin-right: 1.63rem;
            font-family: 'Poppins-semi-bold';
        }
        .info {
            font-size: 1.4rem;
            .clear-selected-btn {
                margin-left: 1rem;
                margin-top: -1px;
                display: inline-block !important;
            }
            .total-sounds {
                margin-right: 0.5rem;
                &.selected {
                    margin: 0 0.5rem;
                }
            }
            &.link {
                cursor: pointer;
                color: $primary;
            }
        }
        .drive-buttons {
            margin-top: 0.8rem;
            color: $primary;
            cursor: pointer;
            .download {
                margin-right: 1rem;
            }
        }
    }
    .header-middle {
        font-size: 1.2rem;
        color: $night-theme-text;
        cursor: default;
        @media screen and (max-width: $bpm-brk-2) {
            display: none;
        }
    }
    .down-key {
        outline: 2px solid $night-theme-text;
        filter: invert(53%) sepia(2%) saturate(79%) hue-rotate(124deg) brightness(86%) contrast(84%);
        padding: 0.8rem 1rem;
        margin: 0 -0.1rem;
        transform: scale(0.6);
        margin-bottom: -0.8rem;
    }
    .header-right {
        color: $night-theme-text;
        font-size: 1.2rem;
        @media all and (max-width: $bpm-brk-4) {
            display: none;
        }
        .form {
            position: relative;
        }
    }
    .search-bar {
        color: $white;
        padding: 0.3rem 3rem 0rem 5rem;
        box-sizing: border-box;
        background-image: none;
        background-color: $black;
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        height: 4.8rem;
        width: 31.6rem;
        font-size: 1.5rem;
        border-radius: 0.3rem;
        .light-theme & {
            background: $white;
            border: 1px solid #e1e1e1;
            color: $night-theme-text;
        }
        &::-webkit-search-cancel-button {
            margin-right: 1rem;
            -webkit-appearance: none;
        }
        &::placeholder {
            color: $night-theme-text;
        }
    }
    .cancel-grey {
        transform: rotate(135deg);
        width: 1.8rem;
        position: absolute;
        right: 1.8rem;
        top: 1.4rem;
        cursor: pointer;
        &.hidden {
            display: none;
        }
    }
    .magnify-icon {
        position: absolute;
        filter: invert(11%) sepia(77%) saturate(6407%) hue-rotate(331deg) brightness(95%) contrast(104%);
        left: 1.8rem;
        height: 1.6rem;
        top: 1.6rem;
    }
}
.SoundsList ul,
.SoundsList .info.filter,
.SoundListHeader {
    // margin-left: 2rem;
    @media all and (max-width: $bpm-brk-4) {
        margin-left: 0;
    }
}
.SoundsList .info.filter {
    margin-top: 1rem;
}

.ManagedSoundlist {
    &.temp-invisible {
        ul {
            visibility: hidden;
        }
    }
    .sticky {
        position: -webkit-sticky; /* Safari */
        position: sticky;
        top: $header-height;
        z-index: 10;
        background-color: $background-light-grey;
        background-color: $sidebar-background-grey;
        .light-theme & {
            background: $white;
        }
    }
    .sticky-target {
        width: 100%;
    }
    @media all and (min-width: $max-width) {
        width: 1494px;
    }
}
