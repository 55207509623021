.PrizeCards {
    .prize-card {
        width: 100%;
        background: #1a1a1a;
        display: flex;
        min-height: 100px;
        margin-bottom: 20px;
        border-radius: 3px;

        .light-theme & {
            background: #fcfcfc;
            border: 1px solid #e1e1e1;
        }

        &:last-child {
            margin-bottom: 0;
        }

        .img-container {
            width: 20%;
            min-width: 75px;
            cursor: pointer;
            margin-right: 20px;
            flex-shrink: 0;

            @media all and (max-width: 830px) {
                display: none;
            }
        }
    }

    .text-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px;
    }

    .title {
        text-transform: uppercase;
        color: #1af085;
        margin-bottom: 5px;
        font-family: 'Poppins-semi-bold';
    }

    .subtext {
        * {
            font-family: 'Poppins' !important;
            color: #707070 !important;
            background-color: transparent !important;
        }

        p {
            line-height: 1.15;
        }

        li {
            list-style: outside;
        }

        color: $white;
        margin-bottom: 4px;

        .light-theme & {
            color: $black;
        }

        :last-child {
            clear: both;
        }
    }

    .view-more {
        display: inline-block;
        padding: 5px;
        margin-top: 10px;
        color: #1af085;
        cursor: pointer;
        @extend .no-select;
    }
}