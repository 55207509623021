.DropdownButton {
    position: relative;
    display: inline-block;
    .chevron-down {
        transform: rotate(-90deg) scale(0.9); // chevron for meu items
    }
    button {
        padding: 0 2rem;
        color: $white;
        background-color: $black;
        font-family: Calibri;
        font-size: 1.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        white-space: nowrap;
        height: 4.8rem;
        border-radius: 0.4rem;
        cursor: pointer;

        &:hover {
            color: white;
        }
        &:disabled {
            background: rgb(36, 36, 36);
            color: rgb(77, 77, 77);
            outline: 1px solid #393939;
            cursor: unset;
        }
        span {
            margin-right: 1rem;
            margin-left: 1rem;
            flex: 1;
        }
        &.medium {
            height: 4rem;
        }
        .light-theme & {
            background: $white;
            color: $night-theme-text;

            // &:hover {
            //     background: map-get($light-theme, button-red-gradient);
            //     color: #f8f8f8;
            //     img {
            //         filter: $white-filter !important;
            //     }
            // }
        }
        .menu-img-container {
            display: flex;
        }
    }

    nav {
        position: absolute;
        white-space: nowrap;
        display: inline-block;
        min-width: 100%;
        background: $black;
        opacity: 0;
        visibility: hidden;
        justify-content: center;
        font-size: 1.4rem;
        border-radius: 0.4rem;
        z-index: 100;
        filter: drop-shadow(0 0 1rem rgba(0, 0, 0, 0.3));
        will-change: filter;
        &.up {
            bottom: 100%;
        }
        &:first-child {
            padding-top: 1rem;
        }
        .light-theme & {
            background-color: map-get($light-theme, white);
            border: 1px solid map-get($light-theme, divider-grey);
            filter: unset;
        }
        .menu-items {
            display: inline-block;
            margin-top: 2rem;
            margin-bottom: 2rem;
            list-style: none;
            min-width: 100%;
            .list-item {
                color: $night-theme-text;
                display: flex;
                position: relative;
                &.selected {
                    &.selected {
                        color: $white !important;
                    }
                }
                &:hover {
                    // background: $background-light-grey;
                    cursor: pointer;
                    color: $white;
                    .menu-items-img {
                        filter: $crimson-bright-filter;
                    }
                    &:not(.selected) {
                        .chevron-down {
                            filter: $white-filter !important;
                        }
                    }
                    .light-theme & {
                        color: map-get($light-theme, black);
                        .chevron-down {
                            filter: $navy-filter !important;
                        }
                    }
                    .subitems-container {
                        display: unset;
                    }
                }
                &.no-chev {
                    text-align: unset;
                }
                .chevron-down {
                    margin-right: 1.5rem;
                    margin-left: 1rem;
                    filter: brightness(0) saturate(100%) invert(50%) sepia(0%) saturate(30%) hue-rotate(163deg)
                        brightness(92%) contrast(86%);

                    &.colored {
                        filter: $crimson-bright-filter !important;
                    }
                    .light-theme & {
                        // filter: $blue-grey-filter;
                    }
                }
                .menu-img-container {
                    width: 5rem;
                    display: flex;
                    justify-content: center;
                }
                .menu-items-text {
                    flex-basis: 10rem;
                    flex-grow: 1;
                    padding: 1rem 0;
                    font-size: 1.4rem;
                }
                .subitems-container {
                    background: $black;
                    border-radius: 0.4rem;
                    z-index: 100;
                    display: none;
                    position: absolute;
                    top: -2.15rem;
                    padding: 2rem 0;
                    white-space: nowrap;
                    overflow-y: scroll;
                    .sublist-item {
                        color: $night-theme-text;
                        padding: 1rem 4rem;
                        display: flex;
                        align-items: center;
                        position: relative;
                        font-size: 1.4rem;
                        &:hover {
                            color: $white;
                            // background: $background-light-grey;
                        }
                        &.selected {
                            color: $white;
                            background: $primary;
                        }
                    }
                    .light-theme & {
                        background-color: map-get($light-theme, white);
                        border: 1px solid map-get($light-theme, divider-grey);
                        .sublist-item {
                            color: map-get($light-theme, text-grey);
                            &:hover {
                                // outline: 1px solid map-get($light-theme, divider-grey);
                                // background: map-get($light-theme, baby-blue);
                                color: map-get($light-theme, black);
                            }
                            &.selected {
                                color: $black;
                                background: map-get($light-theme, baby-blue);
                            }
                        }
                    }
                }
                .chevron-down {
                    &.hidden {
                        visibility: hidden;
                    }
                    &.none {
                        display: none;
                    }
                }
                &.selected {
                    cursor: pointer;
                    background-color: $primary;
                    color: $white;
                    .chevron-down {
                        filter: $white-filter;
                    }
                }
                .light-theme & {
                    color: map-get($light-theme, text-grey);
                }
            }
        }
    }
}
