.IndividualPack {
    .background-image {
        margin: 0 -6rem;
        // max-height: 39rem;
        background-size: cover;
        @media all and (min-width: $max-width) {
            margin: 0;
            width: 100%;
        }
        @media all and (max-width: $bpm-brk-3) {
            margin: 0 -2rem;
        }
    }
    .similar-packs {
        margin-top: 2rem;
    }
    .content-wrapper {
        position: relative;
        display: flex;
        padding: 5.5rem 0;
        margin: 0 4rem;
        @media all and (max-width: $bpm-brk-5) {
        }
        @media all and (max-width: $bpm-brk-2) {
            padding-right: 5.5rem;
        }
        @media all and (max-width: $bpm-brk-5) {
            flex-direction: column;
            align-items: center;
            padding: 5rem 1rem;
        }
    }
    .album-wrapper {
        position: relative;
        margin-right: 5.2rem;
        min-width: 17rem;
        max-height: 32.1rem;
        max-width: 32.1rem;
        @media screen and (max-width: $bpm-brk-5) {
            margin-right: 0;
            margin-bottom: 3rem;
        }
        .heart-svg-mobile {
            display: none;
            position: absolute;

            height: 3.2rem;
            right: 1rem;
            top: 0rem;
            cursor: pointer;
            &:not(.no-fill) {
                filter: $crimson-bright-filter;
            }
            @media screen and (max-width: $bpm-brk-5) {
                display: block;
            }
        }
        .album-cover {
            object-fit: cover;
            width: 100%;
            height: auto;
            border-radius: 0.5rem;
        }
    }

    .content-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .header {
            line-height: 2.5rem;
        }
        @media screen and (max-width: $bpm-brk-5) {
            text-align: center;
        }
        .Title {
            .container {
                display: unset;
                .header {
                    color: $white;
                    display: unset;
                    text-align: center;
                    font-family: 'Poppins';
                    text-transform: uppercase;
                    @media screen and (max-width: $bpm-brk-5) {
                        font-size: 2.4rem !important;
                    }
                }
            }
        }
        .BPM-Button {
            font-size: 1.3rem;
        }
        width: 100%;
        .header {
            @media all and (max-width: $bpm-brk-5) {
                font-size: 1.8rem;
            }
            .light-theme & {
                color: $white;
            }
        }
        .tags-container {
            display: flex;
            // @media all and (max-width: $bpm-brk-3) {
            //     justify-content: center;
            // }
            @media all and (max-width: $bpm-brk-5) {
                margin-top: 2rem;
            }

            .genre-tag {
                padding: 0 2rem;
                height: 3.5rem;
                background: $primary;
                outline: none;
                color: $white;
                border-radius: 18px;
                font-size: 1.4rem;
                margin-left: auto;
                margin-right: 1.5rem;
                .btn-text {
                    white-space: nowrap;
                }
                @media all and (max-width: $bpm-brk-5) {
                    display: none;
                }
            }
            .TagsButtons-grid {
                @media all and (max-width: $bpm-brk-5) {
                    justify-content: center;
                }
                .BPM-Button {
                    padding: 0.7rem 1.4rem;
                    background: $black;
                    border: none;

                    &:hover {
                        background: $primary !important;
                        .btn-text {
                            color: $white !important;
                        }
                        .right-text {
                            color: $white !important;
                        }
                    }
                    .light-theme & {
                        background: $white;
                    }
                    .btn-text {
                        color: #b8b8b8;

                        .light-theme & {
                            color: $black;
                        }
                    }
                    .right-text {
                        .light-theme & {
                            color: $night-theme-text;
                        }
                    }
                }
            }
        }
        .label {
            color: $white;
            margin-bottom: 1.3rem;
            @media all and (max-width: 600px) {
                margin-bottom: 0;
            }
            .name {
                cursor: pointer;
                &:hover {
                    text-decoration: underline;
                }
            }
            .genre-tag-mobile-wrapper {
                display: flex;
                justify-content: center;
                .genre-tag-mobile {
                    padding: 0 2rem;
                    height: 3.5rem;
                    background: $primary;
                    outline: none;
                    color: $white;
                    border-radius: 18px;
                    font-size: 1.4rem;
                    margin-right: 1.5rem;
                    margin-left: 2rem;
                    .btn-text {
                        white-space: nowrap;
                    }
                    @media all and (min-width: $bpm-brk-5) {
                        display: none;
                    }
                    @media all and (max-width: $bpm-brk-6) {
                        margin-top: 1.5rem;
                    }
                    @media all and (max-width: 650px) {
                        margin-top: 1.3rem;
                    }
                }
            }
        }

        .subtext {
            margin: 1rem 0;
            .description {
                font-size: 1.6rem;
                color: rgba($white, 0.64);
                margin-top: 1.3rem;
                max-width: 83rem;
                line-height: 2.4rem;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                margin-bottom: 1rem;
                height: 6.8rem;
                overflow: hidden;
                -webkit-line-clamp: 3; /* number of lines to show */
                -webkit-box-orient: vertical;
                &.un-truncate {
                    height: fit-content;
                    overflow: unset;
                    -webkit-line-clamp: unset; /* number of lines to show */
                }
            }

            @media all and (max-width: $bpm-brk-5) {
                font-size: 1.4rem;
            }
            .showMore {
                color: $white;
                cursor: pointer;
                margin-top: 0.5rem;
                font-size: 12px;
                font-family: 'Poppins-semi-bold';
            }
        }
        .dropdownButtons {
            display: flex;
            flex-wrap: wrap;
            @media screen and (max-width: $bpm-brk-5) {
                align-items: center;
                justify-content: center;
            }
            .findSimilar {
                display: flex;
                margin-left: auto;
                img {
                    filter: $crimson-bright-filter;
                }
                @media all and (max-width: 1600px) {
                    margin-left: 0;
                }
            }
            .DropdownButton {
                align-items: center;
                margin-bottom: 0.6rem;

                @media screen and (max-width: $bpm-brk-2) {
                    margin-bottom: 1rem;
                }
                @media screen and (max-width: 470px) {
                    min-width: 216px;
                    width: 24rem;
                    button {
                        width: 100%;
                    }
                    nav .menu-items {
                        padding-left: 2rem;
                    }
                }
            }

            .heart-svg {
                margin-left: 1rem;
                margin-top: 0.5rem;
                height: 3.2rem;

                cursor: pointer;
                &:not(.no-fill) {
                    filter: $crimson-bright-filter;
                }
                @media screen and (max-width: $bpm-brk-5) {
                    display: none;
                }
            }

            @media screen and (max-width: $bpm-brk-2) {
                &:not(:last-child) {
                    width: 14rem;
                    height: 4rem;
                }
            }
            .DropdownButton {
                margin-right: 1.6rem;
            }
        }
    }
}

@media screen and (max-width: 1330px) {
    .IndividualPack {
        .content-container {
            .dropdownButtons {
            }
        }
        .album-cover {
            max-height: 17rem;
            max-width: 17rem;
        }
    }
}
