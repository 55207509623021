.EditGrid {
    display: flex;
    flex-wrap: wrap;
    max-width: 500px;
    padding: 2rem 1rem;
    
    background: $black;
    filter: drop-shadow(0 0 1rem rgba(0, 0, 0, 0.3));
    .light-theme & {
        background: map-get($light-theme, white);  
        filter: drop-shadow(0 0 1rem rgba(0, 0, 0, 0.3));
    }
    .ButtonPanel {
        margin-left: 2rem;
        .title-container {
            padding: 0;
        }
        .grid {
            display: flex;
            flex-wrap: wrap;
            margin-left: -1rem;
            .grid-child {
                margin-left: 1rem;
                padding: 0.6rem;
                margin-top: 1rem;
            }
        }
    }
    input {
        color: $white;
        box-sizing: border-box;
        background-image: none;
        background-color: $black;
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        height: 4.8rem;
        width: 100%;
        font-size: 1.5rem;
        border-radius: 0.3rem;
        padding-left: 1rem;
        border: 1px solid $keyboard-button-outline;
        .light-theme & {
            background: $white;
            border: 1px solid #e1e1e1;
            color: $night-theme-text;
        }
    }
}