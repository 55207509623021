$primary: #1af085;
@import "../bpm-sounds-generic/src/styles/index.scss";
@import "_Search";

@import "_Trending";
@import "_Keyboard";
@import "_LabelsComponent";
@import "TrendingLabels";

@import "_AccountHeader";
@import "_AccountFooter";

@import "./views/FeaturedPacks";
@import "./views/LabelHeader";
@import "./views/LabelHeader";
@import "./views/FAQ";
@import "./views/OrderHistory";
@import "./views/Live";

@import "./pages/Pricing";
@import "./pages/LabelsPage";
@import "./pages/Account";
@import "./pages/Billing";

@import "./edit/EditPopup";
@import "./edit/EditGrid";

@import "~toastr/toastr.scss";
@import "_QC";

// Contest page
@import "./pages/contest/Contest.scss";
@import "./pages/contest/CommentCards.scss";
@import "./pages/contest/CommentBox.scss";
@import "./pages/contest/DateCards.scss";
@import "./pages/contest/PrizeCards.scss";
@import "./pages/contest/SubmissionCards.scss";
@import "./pages/contest/Submit.scss";
@import "./pages/contest/Header.scss";

@import "./pages/Giveaway-Entry-Popup.scss";

@import "./FAQ";

.toast-top-right {
    top: calc(#{$header-height} + 20px);
}

#toast-container>.toast {
    background-image: none !important;
    background-color: $white;
    box-shadow: 0 0 10px rgba($black, 0.1) !important;
    color: $black;
}

.MuiPopover-paper {
    background: transparent !important;
}

.optimize-contrast,
.optimize-contrast * {
    image-rendering: -webkit-optimize-contrast;
}

:root {
    --primary: #{$primary};
}

.smcx-modal {
    -webkit-box-shadow: 0px 0px 20px 0px rgba(231, 234, 244, 1);
    -moz-box-shadow: 0px 0px 20px 0px rgba(231, 234, 244, 1);
    box-shadow: 0px 0px 20px 0px rgba(231, 234, 244, 1);
    transition: background-color 0.5s ease, box-shadow 0.5s ease, -webkit-box-shadow 0.5s ease,
        -moz-box-shadow 0.5s ease;

    .theme-black & {
        background-color: #252525;
        -webkit-box-shadow: 0px 0px 20px 0px #191919;
        -moz-box-shadow: 0px 0px 20px 0px #191919;
        box-shadow: 0px 0px 20px 0px #191919;
    }

    .smcx-iframe-container {
        margin-top: 10px !important;
    }
}

.smcx-modal-content,
.smcx-modal-close {
    .theme-black & {
        background-color: map-get($map: $theme-colors, $key: "black_background");
    }
}

.bpm-promo-banner {
    position: fixed !important;
    width: 100%;
    z-index: 99999;
    height: 60px;
}

.credits-button:not(.credits-amount) {
    color: black !important;
}

.credits-amount {
    color: white !important;
}