.EditPopup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    height: fit-content;
    z-index: 90;
    overflow: scroll;
    max-height: 82%;
    background: $black;
    filter: drop-shadow(0 0 1rem rgba(0, 0, 0, 0.3));
    .light-theme & {
        background: map-get($light-theme, white);
        filter: drop-shadow(0 0 1rem rgba(0, 0, 0, 0.3));
    }

    @media all and (max-width: 500px) {
        padding: 2.5rem;
    }
    .btn-bar {
        position: absolute;
        right: 1rem;
        top: 1.2rem;
        cursor: pointer;
        & > img {
            margin-right: 1rem;
            display: inline-block;
            width: 1.2rem;
            height: 1.2rem;
            .light-theme & {
                filter: $crimson-bright-filter;
            }
        }
    }

    h1 {
        margin-top: 2rem;
        margin-bottom: 3rem;
        text-align: center;
        font-size: 2rem;
    }
    .content {
        max-width: 100%;
        padding: 2rem;
        input,
        textarea {
            color: $white;
            box-sizing: border-box;
            background-image: none;
            background-color: $black;
            -webkit-appearance: none;
            box-shadow: none;
            border: none;
            height: 4.8rem;
            width: 100%;
            font-size: 1.5rem;
            border-radius: 0.3rem;
            padding-left: 1rem;
            border: 1px solid $keyboard-button-outline;
            .light-theme & {
                background: $white;
                border: 1px solid #e1e1e1;
                color: $night-theme-text;
            }
        }
        textarea {
            height: 120px;
        }
        .DropdownButton {
            width: 100%;
            button {
                padding: 0;
            }
            nav {
                .dark-theme & {
                    border: 1px solid $keyboard-button-outline;
                }
            }
        }
    }
    .with-delete {
        display: flex;
        align-items: center;
        .delete {
            cursor: pointer;
            width: 2.5rem;
            height: 2.5rem;
            margin-bottom: 1.5rem;
            margin-left: 3rem;
        }
    }
    .upload-cover,
    .upload-demo {
        color: $night-theme-text;
    }
    .dx-fieldset {
        margin-bottom: 20px;
    }
    .dx-fieldset .dx-field-value {
        margin: 0;
    }
    .dx-fieldset,
    .dx-fieldset * {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }
    .dx-fieldset-header:empty {
        display: none;
    }
    .dx-field {
        position: relative;
        padding: 0.4em;
    }
    .dx-field:after,
    .dx-field:before {
        display: table;
        content: '';
        line-height: 0;
    }
    .dx-field:after {
        clear: both;
    }
    .dx-field-label {
        float: left;
        width: 40%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .dx-field-value,
    .dx-field-value-static {
        float: right;
        width: 60%;
        word-wrap: break-word;
    }
    .dx-field-value-static {
        white-space: normal;
    }
    .dx-field-value.dx-datebox {
        min-width: 60%;
    }
    .dx-field-value:not(.dx-widget) > .dx-datebox {
        min-width: 100%;
    }
    .dx-field-value .dx-selectbox-tag-container {
        white-space: normal;
    }
    .dx-field-value:not(.dx-widget) > .dx-selectbox.dx-selectbox-multiselect.dx-widget {
        position: relative;
        width: auto;
        text-align: left;
    }
    .dx-fieldset.dx-rtl .dx-field-label,
    .dx-rtl .dx-fieldset .dx-field-label {
        float: right;
    }
    .dx-fieldset.dx-rtl .dx-field-value,
    .dx-rtl .dx-fieldset .dx-field-value {
        float: left;
    }
}

.dimBackground {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 80;
    top: 0;
    left: 0;
    background: rgba(black, 0.4);
    &:not(.dim) {
        display: none;
    }
}
