.Billing {
    background: $sidebar-background-grey;
    .light-theme & {
        background: $white;
    }
    .AccountHeader {
        @media all and (max-width: $bpm-brk-5) {
            margin: 0 -1rem;
        }
    }
    .BPM-Button {
        padding: 1.5rem 3rem;
    }
    main {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        max-width: 830px;
        @media all and (max-width: $bpm-brk-4) {
        }
        .title {
            color: #7c7c7c;
            font-size: 1.8rem;
            @media all and (max-width: $bpm-brk-4) {
                display: flex;
                justify-content: center;
            }
        }
        .current {
            width: 100%;
            .header {
                font-size: 2rem;
                font-family: 'Poppins-semi-bold';
                @media all and (max-width: $bpm-brk-4) {
                    display: flex;
                    justify-content: center;
                }
                .dark-theme & {
                    color: $white;
                }
            }

            .standard-container {
                position: relative;
                background: darken($sidebar-background-grey, 2%);
                width: 100%;
                padding: 5rem 0 !important;
                border: 1px solid $primary;
                border: 1px solid #343434; // lighter grey, potential
                border-radius: 0.7rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                @media all and (max-width: $bpm-brk-4) {
                    max-width: 255px;
                    margin: 0 auto;
                }
                .light-theme & {
                    background: transparent;
                    border: 1px solid #8e8e8e;
                }
                .checkmark {
                    position: absolute;
                    top: 2.5rem;
                    right: 2.5rem;
                }
                .top-text {
                    margin-bottom: 0.8rem;
                    font-size: 2.8rem;
                    color: $primary;
                    font-family: 'Poppins-semi-bold';
                }
                .middle-text {
                    margin-bottom: 0.8rem;
                    font-size: 2.2rem;
                    text-align: center;
                    .dark-theme & {
                        color: $white;
                    }
                }
                .credits-remaining {
                    font-size: 1.5rem;
                    color: $primary;
                    &.no-middle-text {
                        color: $white;
                        .light-theme & {
                            color: $black;
                        }
                    }
                    .light-theme & {
                        color: $primary;
                    }
                }
                .BPM-Button {
                    border: none;
                    border-radius: 0.7rem;
                    height: 45px;
                    &:hover {
                        border: none;
                    }
                }
            }
        }
        .orders {
            width: 100%;
        }
        .large-space {
            height: 49px;
        }
        .small-space {
            height: 23px;
        }
        .available {
            width: 100%;
            // header/title styles
            .regular-rate-text {
                color: rgba($white, 0.6);
                text-align: center;
                font-size: 18px;
                margin-top: 40px;
                margin-bottom: 20px;
                font-family: 'Poppins-semi-bold';
                .membership {
                }
                .light-theme & {
                    color: rgba($black, 0.8);
                }
            }
            .sale-text {
                @extend .regular-rate-text;
                &:not(.sale) {
                    display: none;
                }
                margin-top: 0;
            }
            .title {
                display: none;
            }
            .cards-container {
                display: grid;
                grid-template-columns: repeat(auto-fill, 264px);
                grid-gap: 19px;
                width: 100%;
                justify-content: center;
            }
        }

        .credit-card {
            align-self: flex-start;
            @media all and (max-width: $bpm-brk-4) {
                align-self: center;
            }
            .CreditCard {
                @media all and (max-width: $bpm-brk-4) {
                    width: 34rem;
                    height: 21.5rem;
                    padding-top: 3rem;

                    .text {
                        margin-bottom: 0;
                    }
                }
                .number {
                    letter-spacing: 0.3rem;
                }
                .dark-theme & {
                    border: 1px solid #343434;
                    .text {
                        color: #feff;
                    }
                    .number {
                        color: #feff;
                    }
                }
            }
        }
        .change-card {
            font-size: 14px;
            color: $primary;
            cursor: pointer;
            align-self: start;
            margin-top: 8px;
        }
        .billing-change-payment-method {
            width: 100%;
            margin-top: 8px;
            input {
                background: transparent;
                border: 1px solid #343434;
                border-radius: 0.7rem;
                padding: 0.6rem 1rem;
                color: white;
                font-size: 1.6rem;
                .light-theme & {
                    color: $black;
                    border: 1px solid #e1e1e1;
                }
                &.valid {
                    -webkit-box-shadow: 0 0 0 0.1rem #388e3c !important;
                    box-shadow: 0 0 0 0.1rem #388e3c !important;
                    border: 0;
                }
                &.invalid {
                    background-color: red !important;
                }
            }
            .two-col {
                display: flex;
                input {
                    width: 20rem;
                }
            }
            .content-head {
                margin-bottom: 4px;
            }
        }
    }
    .faq-container {
        padding-top: 10rem;
    }
}
