.LabelsPage {
    .labels-divider-second {
        @media all and (max-width: 463px) {
            display: none;
        }
    }
    .BPM-Button {
        border: 1px solid #2a2b2b;
    }
    .individual-divider {
        @media all and (max-width: 1497px) {
            // display: none;
        }
    }
    .Title {
        @media all and (max-width: 1760px) {
            margin-left: 0;
        }
        @media all and (max-width: $bpm-brk-5) {
            margin-top: 4rem;
        }
        .container {
            @media all and (max-width: 1760px) {
                justify-content: center;
            }

            .header {
                font-size: 2.5rem;
            }
        }
    }
    .header {
        position: relative;
        display: grid;
        align-items: center;
        grid-template-columns: repeat(var(--max-repeat, auto-fill), minmax(194px, 194px));
        justify-content: center;
        grid-gap: 58px 58px;
        @media all and (max-width: 463px) {
            display: flex;
            flex-direction: column-reverse;
        }
        .title {
            font-size: 2.2rem;
            margin-right: 1.63rem;
            font-family: 'Poppins-semi-bold';
            color: $white;
            margin-right: auto;
            .light-theme & {
                color: map-get($light-theme, black);
            }
            @media all and (max-width: 463px) {
                margin: 0;
                margin-top: 2rem;
            }
        }
        .search-labels {
            position: absolute;
            right: 0;
            @media all and (max-width: 650px) {
                top: 3.5rem;
            }
            @media all and (max-width: 463px) {
                position: relative;
            }
            .search-bar {
                color: $white;
                padding: 0.3rem 3rem 0rem 5rem;
                box-sizing: border-box;
                background-image: none;
                background-color: $black;
                -webkit-appearance: none;
                box-shadow: none;
                border: none;
                height: 4.8rem;
                width: 31.6rem;
                font-size: 1.5rem;
                border-radius: 0.3rem;
                @media all and (max-width: $ipad-pro-13) {
                    margin-right: 6rem;
                }
                @media all and (max-width: $bpm-brk-3) {
                    margin-right: 2rem;
                }
                @media all and (max-width: 463px) {
                    margin: 0;
                }
                .light-theme & {
                    background: $white;
                    border: 1px solid #e1e1e1;
                    color: $night-theme-text;
                }
                &::-webkit-search-cancel-button {
                    margin-right: 1rem;
                    -webkit-appearance: none;
                }
                &::placeholder {
                    color: $night-theme-text;
                }
            }
            .cancel-grey {
                transform: rotate(135deg);
                width: 1.8rem;
                position: absolute;
                right: 1.8rem;
                top: 1.4rem;
                cursor: pointer;
                &.hidden {
                    display: none;
                }
            }
            .magnify-icon {
                position: absolute;
                filter: invert(11%) sepia(77%) saturate(6407%) hue-rotate(331deg) brightness(95%) contrast(104%);
                left: 1.8rem;
                height: 1.6rem;
                top: 1.6rem;
            }
        }
    }
}
