.faq-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $background-light-grey;
    .light-theme & {
        background-color: #f8f8f8;
    }
    .faq-inner {
        margin: 0 16px;
        width: 80%;
        max-width: 800px;
        .faq-headline {
            margin: 16px;
            display: flex;
            justify-content: center;
            span {
                font-size: 2.4rem;
                font-weight: 700;
                color: $white;
                text-align: center;
                .light-theme & {
                    color: $black;
                }
                @media all and (max-width: 800px) {
                    font-size: 14px;
                }
               
            }
        }

        .faq-item {
            &:not(:last-child) {
                border-bottom: $border-grey;
                .light-theme & {
                    border-bottom: 1px solid #e5e5e5;
                }
            }

            padding: 0px 8px;
            
            .question {
                display: flex;
                height: 72px;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                transition: all 0.1s ease-in-out;
                color: $white;
                .light-theme & {
                    color: $black;
                }
                cursor: pointer;

                span {
                    display: flex;
                    flex: 1;
                    font-size: 18px;
                    @media all and (max-width: 500px) {
                        font-size: 14px;
                    }
                }

                .icon {
                    width: 18px;
                    height: 11px;
                    object-fit: contain;
                    margin-left: 8px;
                    transition: all 0.1s ease-in-out;

                    &.open {
                        transform: rotate(-180deg);
                        transition: all 0.1s ease-in-out;
                    }
                }
            }

            .answer {
                margin-bottom: 32px;

                span {
                    line-height: 24px;
                    font-size: 14px;
                    color: $white;
                    .light-theme & {
                        color: $white;
                    }
                }
            }
        }
    }
}
