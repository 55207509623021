.Tooltip {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    border-radius: 0.6rem;
    background: $primary;
    color: white;
    right: 0;
    top: 3.5rem;
    // width: 11.5rem;
    padding: 0 1rem;
    z-index: 2;
    pointer-events: none;

    .text {
        margin: 0.7rem 0;
        white-space: nowrap;
        font-size: 1.4rem;
    }

    // div {
    //     margin: 0.7rem 0;
    //     margin-left: 0.7rem;
    // }
    // // MIDI ICON
    .light-theme & {
        background: map-get($light-theme, button-red-gradient);
    }
}
