.CommentCards {
    scroll-behavior: smooth;

    * {
        scroll-behavior: smooth;
    }

    .comment-card {
        .CommentBox {
            width: 100%;
        }

        border-top: 1px solid rgba($white, 0.1);
        padding: 22px 10px;
        display: flex;
        align-items: center;

        .light-theme & {
            border-top: 1px solid #e1e1e1;
        }

        .image {
            flex: 0 0 40px;
            width: 40px;
            height: 40px;
            margin-right: 20px;
            border-radius: 50%;
        }

        .text {
            font-size: 14px;
        }

        .username {
            color: $white;
            margin-right: 10px;

            .light-theme & {
                color: $black;
            }
        }

        .verified {
            margin-bottom: -0.3rem;
            width: 16px;
            height: 16px;
            margin-right: 10px;

            &.unverified {
                display: none;
            }
        }

        .date {
            color: rgba($white, 0.2);

            @media all and (max-width: 414px) {
                display: block;
                margin-top: 4px;
            }

            .light-theme & {
                color: rgba($black, 0.4);
            }
        }

        .description {
            margin-top: 4px;
            color: rgba($white, 0.4);

            .light-theme & {
                color: $night-theme-text;
            }
        }

        &:hover {
            .buttons {
                display: flex !important;
            }
        }

        .buttons {
            margin-left: auto;
            display: none;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .trash,
            .edit {
                cursor: pointer;
                margin-right: 0;
                width: 30px;
                width: 27px;
                height: 27px;
                margin-bottom: 5px;

                &:hover {
                    filter: $crimson-bright-filter  !important;
                }

                &:not(.remove-fill) {
                    filter: $white-filter;

                    .light-theme & {
                        filter: $night-theme-text-filter;
                    }
                }
            }

            .like-count {
                color: rgba(#fff, 0.25);
                cursor: default;
                @extend .no-select;

                .light-theme & {
                    color: rgba(#000, 0.4);
                }
            }
        }
    }

    .end-of-comments {
        color: white;
        display: flex;
        justify-content: center;
        font-size: 16px;
        transition: all 1s;

        .light-theme & {
            color: black;
        }

        &:not(.show) {
            height: 0;
            transition: all 1s;
            opacity: 0;
            pointer-events: none;
        }
    }

    .view-more {
        display: inline-block;
        padding: 5px;
        margin-top: 10px;
        color: #1af085;
        cursor: pointer;
        @extend .no-select;

        &.end {
            pointer-events: none;
            transition: all 500ms;
            color: rgba($primary, 0);
        }
    }
}