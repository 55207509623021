.Giveaway-Entry-Popup {
    transform: translate(-50%, -50%);
    top: calc(50vh + #{$header-height});
    left: calc(50% + 14rem);
    @media screen and (max-width: $bpm-brk-3) {
        left: 50%;
    }
    position: absolute;
    z-index: 1600;
    min-width: 300px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    
    border-radius: 0.3em;
    padding: 2rem 4rem;

    background: $black;
    color: $white;
    overflow: hidden;
    .light-theme & {
        border: 1px solid #e1e1e1;
        background: $white;
        color: $black;
    }

    .wrapper {
        padding: 10px;
    }

    .Entry {
        position: absolute;
        width: 100%;
        height: 100%;
        transition: top 1s;
        top: 0;
        &.closed {
            top: 100%;
            visibility: hidden;
        }

        .BPM-Button {
            margin-left: auto;
            width: 14rem;
        }
    }

    .SignUp {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: top 1s;
        overflow: hidden;
        &.closed {
            top: -150%;   
        }
        .input-error {
            color: $primary;
            margin-top: 0.5rem;
        }
        h1 {
            font-size: 3rem;
            margin-bottom: 2rem;
        }
        input {
            background: transparent;
            border: 1px solid #343434;
            border-radius: 0.7rem;
            padding: 1rem 2rem;
            width: 28rem;
            margin: 0 auto;
            height: 5rem;
            color: white;
            font-size: 1.4rem;
            text-align: center;
            .light-theme & {
                color: $black;
                border: 1px solid #e1e1e1;
            }
        }
        .text-label {
            color: map-get($light-theme, text-grey);
            margin-top: 2rem;
            margin-bottom: 1rem;
            font-size: 1.6rem;
        }
        .subtext {
            font-size: 2rem;
            
        }
        form {
            display: flex;
            flex-direction: column;
            width: 520px;
            @media all and (max-width: $bpm-brk-4) {
                width: 100%;
            }
            .BPM-Button {
                max-width: 120px;
                margin-top: 20px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    .close {
        position: absolute;
        right: 1.5rem;
        top: 1.5rem;
        filter: $crimson-bright-filter;
    }

    p {
        font-size: 2rem;
        margin-top: 0;
        margin-bottom: 10px;
        padding: 0 15px !important;
        @media all and (max-width: 550px) {
            padding: 0 !important;
        }
    }

    h1 {
        font-size: 4rem;
        font-weight: bold;
        margin-bottom: 4px;
        margin-top: 4px;
    }
    .vl {
        height: 1px;
        width: 100%;
        background-color: white;
        .light-theme & {
            background-color: black;
        }
    }
    .popup {
        line-height: 30px !important;
    }
    .list {
        .item {
            display: flex;
            width: 100%;
            align-items: center;
            margin-bottom: 1.4rem;
            .text {
                font-size: 2rem;
            }
            .btn {
                margin-left: auto;
                width: 12rem;
            }
        }
    }
    .chances {
        margin-top: 20px;
        font-weight: 600;
        font-size: 2.4rem;
    }
    .link {
        margin-top: 10px;
        font-weight: bold;
        font-size: 1.8rem;
        color: $primary;
        cursor: pointer;
    }
    @media all and (max-width: 640px) {
        padding: 2rem 4rem;
        width: 90%;
    
        .popup {
            font-size: 24px !important;
        }
    }
    @media all and (max-width: 320px) {
        padding: 2rem 2rem;
        width: 90%;
    }
}
.Giveaway-Entry-Popup-dark-background {
    position: absolute;
    top: -20%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.5);
    z-index: 1500;
}