.CreditCard {
    width: 40rem;
    height: 24rem;
    border: 1px solid #e1e1e1;
    border-radius: 0.7rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4rem;
    .icon {
        height: 2.4rem;
        width: auto;
        margin-bottom: 2rem;
    }
    .text {
        font-size: 2rem;
        color: $black;
        margin-bottom: 1rem;
    }
    .number {
        font-size: 2rem;
        color: $black;
        letter-spacing: 0.2rem;
    }
    .change-card {
        font-size: 14px;
        color: $primary;
        margin-top: auto;
        cursor: pointer;
    }
}
