.react-tagsinput-tag {
    white-space: nowrap;
    .tag-key {
        font-weight: 600;
        .light-theme & {
            color: map-get($light-theme, $black);
        }
    }
    color: $white;
    display: inline-block;
    font-weight: 400;
    margin-top: 4px;
    margin-right: 5px;
    padding: 5px;
    .light-theme & {
        color: $night-theme-text;
    }
}

.react-tagsinput-remove {
    cursor: pointer;
    font-weight: bold;
}

.react-tagsinput-tag a::before {
    content: ' ×';
}
