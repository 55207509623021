.MuiSlider {
    &-root {
        color: $primary !important;
    }
    &-rail {
        color: $night-theme-text;
    }
    &-thumb {
        box-shadow: none !important;
    }
}

.BPMSlider {
    &:not(&.only-slider) {
        padding: 2rem 3rem;
        padding-right: 5rem;
        background: $black;
        border-radius: 0.5rem;
    }

    filter: drop-shadow(0 0 1rem rgba(0, 0, 0, 0.3));
    will-change: filter;
    @media all and (max-width: 360px) {
        transform: scale(0.9);
        margin-top: -1rem;
    }
    @media all and (max-width: 320px) {
        margin-left: -1.3rem;
    }
    .light-theme & {
        background: $white !important;
    }
    .slider-caption {
        font-size: 1.6rem;
        color: $night-theme-text;
        &.left {
            margin-right: 1.5rem;
            margin-top: -0.2rem;
        }
    }
    .slider-container {
        display: flex;
        align-items: center;
        .slider {
            width: 22rem;
        }

        .sliderMin {
            font-size: 1.6rem;
            color: $night-theme-text;
            margin-right: 2rem;
            width: 1.6rem;
        }
        .sliderMax {
            font-size: 1.6rem;
            color: $night-theme-text;
            margin-left: 2rem;
            width: 1.6rem;
        }
    }
    .checkbox-text-container {
        margin-top: 1.6rem;
    }
    .label {
        position: relative;
        margin-top: 0.4rem;
        .text {
            color: $night-theme-text;
            margin-left: 3.4rem;
            font-size: 1.6rem;
        }
        #checkBox {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            & + label:before {
                cursor: pointer;
                left: 0.3rem;
                content: '';
                position: absolute;
                top: 0.1rem;
                transform: translateY(-50%);
                width: 1.8rem;
                height: 1.8rem;
                border-radius: 0.2rem;
                background-color: transparent;
                margin-top: 1rem;
                .light-theme & {
                    background: map-get($light-theme, off-white-primary);
                }
            }
            &:not(:checked) {
                & + label:before {
                    border: 1px solid rgba($night-theme-text, 0.5);
                    .light-theme & {
                        border: 1px solid #dbdbdb;
                    }
                }
            }
        }
        #checkBox:checked + label:before {
            position: absolute;
            line-height: 2rem;
            content: '\2713';
            text-align: center;
            color: white;
            font-size: 1.2rem;
            background-color: $primary;
        }
        .text {
            text-align: left;
            font-size: 1.6rem;
        }
    }
}
