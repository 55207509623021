.LabelHeader {
    .background-image {
        // max-height: 39rem;
        background-size: cover;
        @media all and (max-width: 1800px) {
            margin: 0 -6rem; // if you want to make it stretch all the way across
        }
    }
    .similar-packs {
        margin-top: 2rem;
    }
    .content-wrapper {
        position: relative;
        display: flex;
        padding: 5.5rem 0;
        margin: 0 6rem;
        @media all and (max-width: $bpm-brk-3) {
            flex-direction: column;
            align-items: center;
        }
    }
    .album-wrapper {
        position: relative;
        margin-right: 5.2rem;
        min-width: 17rem;
        width: 60rem;
        @media all and (max-width: $bpm-brk-3) {
            margin-right: 0;
        }
        @media all and (max-width: $bpm-brk-3) {
            width: 45rem;
        }
        .heart-svg-mobile {
            display: none;
            position: absolute;

            height: 3.2rem;
            right: 1rem;
            top: 0rem;
            cursor: pointer;
            &:not(.no-fill) {
                filter: $crimson-bright-filter;
            }
        }
        .album-cover {
            object-fit: cover;
            width: 100%;
            height: auto;
            border-radius: 0.5rem;
        }
    }

    .content-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .header {
            line-height: 2.5rem;
        }
        .Title {
            margin: 0 !important;

            @media all and (max-width: $bpm-brk-3) {
                display: none;
            }
            .container {
                display: unset;
                .header {
                    display: unset;
                    text-align: center;
                    font-family: 'Poppins';
                    text-transform: uppercase;
                }
            }
        }
        .BPM-Button {
            font-size: 1.3rem;
        }
        width: 100%;
        .header {
            .light-theme & {
                color: $white;
            }
        }
        .tags-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            @media all and (max-width: $bpm-brk-5) {
                margin-top: 2rem;
            }
            @media all and (max-width: $bpm-brk-3) {
                align-items: center;
                margin-top: 0;
            }
            .view-all-btn {
                padding: 0 2rem;
                height: 3.5rem;
                background: $white;
                outline: none;
                color: $black;
                border-radius: 18px;
                font-size: 1.4rem;
                // margin-left: auto;
                margin-right: 1.5rem;
                margin-top: 1.8rem;
                margin-bottom: 1.8rem;
                .btn-text {
                    white-space: nowrap;
                }
                @media all and (max-width: $bpm-brk-3) {
                    margin-right: 0;
                    margin-bottom: 2.5rem;
                }
            }
            .TagsButtons-grid {
                @media all and (max-width: $bpm-brk-3) {
                    justify-content: center;
                }
                .BPM-Button {
                    padding: 0.7rem 1.4rem;
                    background: $black;
                    border: none;

                    &:hover {
                        background: $primary !important;
                        .btn-text {
                            color: $white !important;
                        }
                        .right-text {
                            color: $white !important;
                        }
                    }
                    .light-theme & {
                        background: $white;
                    }
                    .btn-text {
                        color: #b8b8b8;

                        .light-theme & {
                            color: $black;
                        }
                    }
                    .right-text {
                        .light-theme & {
                            color: $night-theme-text;
                        }
                    }
                }
            }
        }
        .subtext {
            margin: 1.2rem 0;
            @media all and (max-width: $bpm-brk-3) {
                flex-direction: column;
                display: flex;
                align-items: center;
            }
            .description {
                font-size: 1.6rem;
                color: rgba($white, 0.64);
                margin-top: 1.3rem;
                max-width: 83rem;
                line-height: 2.4rem;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                margin-bottom: 1rem;
                height: 6.8rem;
                overflow: hidden;
                -webkit-line-clamp: 3; /* number of lines to show */
                -webkit-box-orient: vertical;
                &.un-truncate {
                    height: fit-content;
                    overflow: unset;
                    -webkit-line-clamp: unset; /* number of lines to show */
                }
                @media all and (max-width: $bpm-brk-3) {
                    text-align: center;
                }
            }

            .showMore {
                color: $white;
                cursor: pointer;
                margin-top: 0.5rem;
                font-size: 12px;
                font-family: 'Poppins-semi-bold';
            }
        }
        .dropdownButtons {
            display: flex;
            flex-wrap: wrap;
            .findSimilar {
                display: flex;
                margin-left: auto;
                img {
                    filter: $crimson-bright-filter;
                }
            }
            .DropdownButton {
                align-items: center;
                margin-bottom: 0.6rem;
            }

            .heart-svg {
                margin-left: 1rem;
                margin-top: 0.5rem;
                height: 3.2rem;

                cursor: pointer;
                &:not(.no-fill) {
                    filter: $crimson-bright-filter;
                }
            }

            .DropdownButton {
                margin-right: 1.6rem;
            }
        }
    }
}
