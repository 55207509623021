.TagFilter {
    .light-theme & {
        background: $white;
    }
    .tags-section-container {
        width: 100%;
        .animate-height-container {
            .AnimateHeight {
                flex-direction: column;
                align-items: center;
                max-height: 100%;
                display: flex;
            }
        }

        .search-container {
            position: relative;
            height: 3.6rem;
            flex-grow: 0;
            flex-shrink: 0;
            margin: 4px;
            .magnify-icon {
                position: absolute;
                filter: invert(11%) sepia(77%) saturate(6407%) hue-rotate(331deg) brightness(95%) contrast(104%);
                left: 1.8rem;
                height: 1.45rem;
                top: 1.1rem;
                z-index: 99;
                width: 1.15rem;
            }
            .search-bar {
                height: 100%;
                border: none;
                background-image: none;
                background-color: transparent;
                -webkit-appearance: none;
                box-shadow: none;
                width: 13.5rem;
                border-radius: 0.3rem;

                .light-theme & {
                    border: 1px solid #e1e1e1;
                }
                &[type='search'] {
                    color: white;
                    padding: 0.1rem 0 0 3.6rem;
                    font-size: 1.3rem;
                    outline: none;
                    box-sizing: border-box;
                    .light-theme & {
                        color: $grey;
                    }
                }
                &::placeholder {
                    color: $night-theme-text;
                }
            }
        }

        .tags-container {
            max-width: 92rem;
            flex: 1;
            padding: 0 1rem 0 2rem;
            height: 100%;
            button:first-child {
                img {
                    margin-left: 2vw;
                }
            }
            &.tags {
                overflow-y: hidden;
                flex: 1;
            }
            .TagsButtons-grid {
                justify-content: center;
                .BPM-Button {
                    margin-right: 0.6rem;
                    margin-top: 1.2rem;
                    .light-mode & {
                        border: 1px solid #e1e1e1;
                    }
                    .btn-text {
                        color: #b8b8b8;

                        .light-theme & {
                            color: $night-theme-text;
                        }
                    }
                    .right-text {
                        color: $night-theme-text;

                        .light-theme & {
                            color: #b8b8b8;
                        }
                    }
                    &:hover {
                        border: 1px solid transparent;
                        background: $primary;
                        .btn-text {
                            color: white !important;
                        }
                        .right-text {
                            color: white !important;
                        }
                    }
                    &.selected {
                        background: $primary;
                        .btn-text {
                            color: white !important;
                        }
                        .right-text {
                            color: white !important;
                        }
                    }
                }
            }
        }

        .more-button {
            height: 24px;
            flex-grow: 0;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-top: 1.5rem;
            margin-bottom: 2rem;
            font-size: 1.2rem;
            color: $night-theme-text;
            img {
                width: 1rem;
                margin-bottom: 0.2rem;
                margin-left: 0.7rem;
                filter: invert(49%) sepia(13%) saturate(0%) hue-rotate(160deg) brightness(65%) contrast(80%);
            }
            &.rotate {
                img {
                    transform: rotate(180deg);
                }
            }
        }
    }
}
