.wave-form {
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    cursor: pointer;
    position: relative;
    .colored-wave {
        height: 100%;
        max-width: 100%;
        width: 0%;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        .colored-wave-inner,
        .colored-wave-inner-gray {
            height: 100%;
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }
        .colored-wave-inner-gray {
            .dark-theme & {
                filter: invert(30%) sepia(0%) saturate(1%) hue-rotate(243deg) brightness(200%) contrast(30%);
            }
            .light-theme & {
                filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(243deg) brightness(0%) contrast(60%);
            }
        }
    }
}
