.Filter-Divider {
    height: 1px;
    width: calc(100%);
    background-color: rgba(194, 194, 194, 0.2);
}
.Filter {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: -2rem;
    .filter-by-text {
        color: $night-theme-text;
        padding-right: 2rem;
    }
    .DropdownButton {
        .menu-items-img {
            transition: transform 0.2;
        }
        &.open {
            .menu-items-img {
                transform: rotate(-180deg);
            }
        }
        button {
            background: transparent;
            color: $night-theme-text !important;
            span {
                margin-left: 0;
            }
            .menu-img-container {
                filter: $night-theme-text-filter;
            }
            .light-theme & {
                &:hover {
                    filter: $black-filter;
                }
            }
            &:hover {
                color: $white !important;
                background: none !important;
                .menu-img-container {
                    filter: $white-filter;
                }
            }
        }
        &.has-filter {
            button {
                color: $white !important;
                .menu-img-container {
                    filter: $white-filter !important;
                }
                .light-theme & {
                    filter: $black-filter;
                }
            }
        }
    }

    .single-button-container {
        div:first-child {
            nav {
                width: 17rem;
            }
        }
        .DropdownButton {
            button {
                outline: transparent;
                background: transparent;
                .menu-img-container {
                    filter: $night-theme-text-filter;
                }
                .light-theme & {
                    &:hover {
                        filter: $black-filter;
                    }
                }
                &:hover {
                    .menu-img-container {
                        filter: $white-filter;
                    }
                }
            }
        }
        .reset-button {
            display: none;
            cursor: pointer;
            margin-top: 1.5rem;
            text-decoration: underline;
            font-size: 1.5rem;
            color: $primary;
            img {
                filter: invert(49%) sepia(13%) saturate(0%) hue-rotate(160deg) brightness(65%) contrast(80%);
                margin-left: 0.5rem;
            }
        }

        .only-midi {
            display: flex;
            display: none;
            align-items: center;
            margin-top: 1.5rem;
            cursor: pointer;
            .text {
                font-size: 1.3rem;
                color: $night-theme-text;
                margin-right: 1rem;
            }
            .midi-icon {
                width: 1.8rem;
                &:not(.no-filter) {
                    filter: brightness(0) saturate(100%) invert(46%) sepia(9%) saturate(9%) hue-rotate(334deg)
                        brightness(80%) contrast(84%);
                }
            }
        }
    }
    .single-button-container {
        display: flex;
        flex-wrap: wrap;
        .BPM-Button {
            height: 4.8rem;
        }
    }
}

// Stuff for material ui popover
.MuiPopover-paper {
    overflow: visible !important;
    overflow-x: visible !important;
    overflow-y: visible !important;
}
