.TrendingLabels {
    @extend .AlbumRow;
    .Title {
        margin-bottom: 3.5rem;
    }
    .labels {
        flex-wrap: unset;
        display: flex;
        overflow-x: scroll;

        .labelCard {
            cursor: pointer;
            height: 150px;
            width: 200px;
            min-width: 200px;
            background: #080504;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0.7rem;
            margin-right: 2.64rem;
            @media (max-width: 650px) {
                margin-right: 1.2rem;
            }
            .light-theme & {
                background: $white;
                border: 1px solid #e8e8e8;
                border-radius: 0.7rem;
            }
            .label {
                max-height: 80%;
                .light-theme & {
                    filter: $black-filter;
                }
            }
        }
    }
}
