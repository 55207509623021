// Imports for the FAQ Section dropdown
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/transitions';

@import './reset';
@import './theme';
@import '_SearchAutocomplete';
@import './general';
@import './toggles';
@import './HeaderBar';
@import './AccountMenu';
@import './Sidebar';
@import './AlbumRow';
@import './AlbumHeader';
@import './Player';

@import './Filter';
@import './TagFilter';

@import './Button';
@import './DropdownButton';
@import './TagsButtons';
@import './Loader';
@import './Title';
@import './DropdownMenu';
@import './Popup';
@import './WaveForm';
@import './Tooltip';
@import './ShareModal';

@import './CreditCard';
@import './Carousel';
@import './SoundsList';
@import './HamburgerMenu';
@import './CardSale';
@import './BPMSlider';
@import './ButtonPanel';

@import './Subscribe';
@import './PaymentMethod';
@import './FAQ.scss';

@import './card/card.scss';
:root {
    --balloon-color: #c12ee5;
    --balloon-font-size: 14px;
}
