.red {
    background-color: $primary !important;
}

.white {
    color: white !important;
}

.filter-white {
    filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(253deg) brightness(102%) contrast(101%) !important;
}

.crimson-bright-background {
    background: #c12ee5 !important;
}

.display-unset {
    display: unset !important;
}

.hide {
    display: none !important;
}

.hidden {
    visibility: hidden;
}

// For Switch text
.fadeToggle {
    opacity: 1 !important;
    visibility: visible !important;
    transition: opacity 0.3s !important;
}

.fadeToggle-reverse {
    opacity: 0 !important;
    transition: opacity 0.3s ease-in-out;
    visibility: hidden !important;
}

// For Dropdowns
.slide-down {
    opacity: 1 !important;
    visibility: visible !important;
    transition: all 0.3s !important;
    transform: translateY(1rem) !important;
}

.slide-up {
    transform: translateY(0rem) !important;
    opacity: 0 !important;
    transition: all 0.3s !important;
    pointer-events: none !important;
}

.collapse-sidebar {
    transform: translateX(-#{$sidebar-width - $sidebar-collapsed-width});
    transition: $sidebar-transition-duration;
}

.open-sidebar {
    transform: translateX(0rem);
    transition: $sidebar-transition-duration;
}

.button-panel-slide-left {
    margin-left: 11.2vw !important;
    transition: 1s;
}

.button-panel-slide-right {
    margin-left: 32.3rem !important;
    transition: 1s;
}

.rotate-left {
    transform: rotate(180deg);
    // transition: transform $sidebar-transition-duration;
}

.rotate-right {
    transform: rotate(0deg);
    // transition: transform $sidebar-transition-duration;
}

.light-theme {
    background-color: map-get($light-theme, white);
}

.no-scrollbar {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}
.no-scrollbar::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

.background-grey {
    background: $button-background-grey !important;
    transition: 0.15s;
    .light-theme & {
        background: map-get($light-theme, light-grey-secondary) !important;
    }
}

.background-crimson-bright {
    background: $primary !important;
    transition: 0.15s;
    .light-theme & {
        background: map-get($light-theme, button-red-gradient) !important;
    }
}

.highlight {
    background: $primary !important;
    transition: 0.15s;
    .letter {
        color: white !important;
    }
    .light-theme & {
        background: $primary !important;
        .letter {
            color: $white !important;
        }
    }
}

.highlight-minor {
    background: $primary !important;
    transition: 0.15s;
    span {
        color: white !important;
    }
    .light-theme & {
        // background: map-get($light-theme, light-grey-secondary) !important;
        .span {
            color: $black !important;
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}

.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.no-drag {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
}

.flex {
    display: flex;
}

.flex-column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.album-button-disabled {
    background: $button-background-grey !important;
    transition: 0.15s;
    & img {
        filter: invert(75%) sepia(16%) saturate(284%) hue-rotate(178deg) brightness(40%) contrast(40%) !important;
    }
    .light-theme & {
        background: #f0f0f0 !important;
        & img {
            filter: $night-theme-text-filter;
        }
    }
}

.center {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
