// overwrite generic styles

.faq-container {
    padding-bottom: 15rem;
    .answer span {
        color: $night-theme-text !important;
        font-size: 14px !important;
        a {
            color: $primary;
            text-decoration: underline;
        }
        b {
            .light-theme & {
                color: $black;
            }
            .dark-theme & {
                color: $white;
            }
        }
    }
}
