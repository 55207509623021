// TODO: Clean this up big time

.FeaturedPacks {
    .header .left-container {
        margin-bottom: 3.4rem;
    }
    @extend .AlbumRow;
    .content.albums-container {
        display: grid;
        grid-template-columns: repeat(10, 201px);
        width: 100%;
        overflow-x: scroll;
        grid-row-gap: 19px;
        @media (min-width: 1px) and (max-width: $mac-15) {
            grid-template-columns: repeat(10, 150px);
            grid-template-rows: repeat(2, 150px);
            grid-column-gap: 2.64rem;
            grid-row-gap: 2.64rem;
            height: 36.5rem;
        }
        @media (max-width: 650px) {
            grid-column-gap: 1.2rem;
            grid-row-gap: 1.2rem;
        }
        .album-and-text-container {
            margin-top: 0;
            margin-right: 0;
            .cover-container {
                margin: 0;
                height: 100%;
                .play-button {
                    top: 50% !important;
                }
            }
            &:first-child {
                @media (min-width: $bpm-brk-3) and (max-width: $mac-15) {
                    width: 36rem !important;
                }
                .clickables-container {
                    @media (min-width: $bpm-brk-3) and (max-width: $mac-15) {
                        width: 100% !important;
                    }
                }
                .cover-container {
                    .cover {
                        @media (min-width: $bpm-brk-3) and (max-width: $mac-15) {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
            // @media all and (max-width: $bpm-brk-4) {
            //     .clickables-container {
            //         padding: 0;
            //         .ellipses {
            //             padding: 0.7rem;
            //         }
            //         .play-button {
            //             top: 32%;
            //             left: 32%;
            //             transform: scale(0.8);
            //         }
            //         .heart {
            //             margin-left: 0.5rem;
            //         }
            //     }

            //     .cover-container {
            //         .cover {
            //             @media all and (max-width: $bpm-brk-4) {
            //                 height: 100%;
            //                 width: 100%;
            //             }
            //         }
            //     }
            // }

            @media all and (min-width: $bpm-brk-3) {
                &:first-child {
                    max-width: unset;
                    width: 40rem;
                    height: auto;
                    grid-row-start: 1;
                    grid-row-end: 3;
                    grid-column-start: 1;
                    grid-column-end: 3;
                    .clickables-container {
                        padding: 3rem !important;
                        width: 103.5%;
                        .heart {
                            transform: scale(1.5);
                        }
                        .ellipses {
                            top: 3rem;
                            right: 1.5rem;
                        }
                    }
                    .cover {
                        width: 42rem;
                        height: 42rem;
                    }
                    .play-button {
                        transform: translate(-50%, -50%) scale(1.5);
                        top: 48%;
                        left: 52.7%;
                    }
                }
                &:nth-child(n + 18) {
                    display: none;
                }
            }
            .text-container {
                display: none;
            }
        }
    }
}
