.CommentBox {
    textarea {
        border-radius: 5px;
        background: transparent;
        border: 1px solid rgba(#fff, 0.1);
        padding: 10px;
        color: #fff;
        height: 80px;
        width: 60%;
        font-size: 14px;
        margin-bottom: 20px;

        @media all and (max-width: 500px) {
            width: 100%;
        }

        .light-theme & {
            border: 1px solid #e1e1e1;
            color: $night-theme-text;
        }
    }

    button {
        border: 1px solid #1af085;
        border-radius: 6px;
        margin-left: 55px;
        padding: 14px 21px;

        span {
            font-size: 14px;
        }

        &:hover {
            border: 1px solid #1af085;
        }
    }

    .user-image {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 15px;
    }
}