$primary: $black;
$primary_white: $white;

.video-split-view {
    height: 100%;

    .vid-container {
        height: 100%;
        width: 100%;

        @media all and (max-width: $bpm-brk-3) {
            margin-bottom: 3rem;
        }

        .video-title-container {
            display: flex;
            align-items: center;

            .live-text {
                color: $white;
                background: $primary;
                border-radius: 0.5rem;
                height: 2rem;
                display: flex;
                align-items: center;
                padding: 0 1.2rem;
                margin-left: 1.5rem;
                letter-spacing: 0.1rem;
                font-size: 12px;
            }
        }

        .video-title {
            color: $primary;

            .dark-theme & {
                color: $primary_white;
            }

            font-size: 26px;
        }

        .video-container {
            width: 100%;
        }

        .under-vid-container {
            display: flex;
            justify-content: space-between;

            .view-text-container {
                display: flex;
                align-items: center;

                .light-theme & {
                    filter: unset !important;
                }

                filter: $white-filter;

                .eye-svg {
                    width: 3.5rem;
                    height: 3.5rem;
                    margin-right: 1rem;
                }

                .view-text {
                    color: $white;

                    .light-theme & {
                        color: $black;
                    }
                }
            }

            .share-container {
                display: flex;
                align-items: center;
                margin-right: 6rem;
                cursor: pointer;

                @media all and (max-width: $bpm-brk-3) {
                    margin-right: 2rem;
                }

                .share-icon {
                    width: 2.5rem;
                    height: 2.5rem;
                    margin-right: 1rem;

                    .dark-theme & {
                        filter: $white-filter;
                    }
                }

                .share-text {
                    @extend .view-text;

                    .dark-theme & {
                        color: $white;
                    }
                }
            }
        }
    }

    .chat-container {
        flex: 400px;
        max-width: 400px;
        display: flex;
        flex-direction: column;
        height: 100%;
        background-color: #fcfcfc;

        .dark-theme & {
            background-color: darken(#1e1e1e, 20%);
        }

        .top-text-container {
            padding-top: 20px;
            padding-left: 20px;
            display: flex;
            flex-direction: column;

            .above-title {
                color: $primary;

                .dark-theme & {
                    color: $primary_white;
                }

                font-size: 15px;
            }

            .under-title {
                color: $primary;

                .here-text {
                    color: $primary;
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                .dark-theme & {
                    color: $primary_white;
                }
            }
        }

        .divider {
            width: calc(100% + 4rem);
            height: 1px;
            background: rgb(33, 37, 41);
            margin-top: 20px;
            margin-left: -2rem;

            .light-theme & {
                background: #ced4da;
            }
        }

        .row {
            display: flex;

            &.chat {
                padding: 20px;
            }

            &.message-box {
                padding: 1rem;

                input {
                    background-color: #fcfcfc;
                }
            }
        }

        .col {
            padding: 0;
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
            position: relative;
            width: 100%;

            &.list {
                padding: 2rem;
                padding: 0;
            }
        }

        .chat-message {
            padding: 0 20px;
            display: flex;
            flex-direction: column;

            .username {
                display: flex;
                align-items: center;
            }

            .admin-icon {
                width: 1.5rem;
                height: 1.5rem;
            }

            &:not(:first-child) {
                margin-top: 20px;
            }
        }

        .click-emoji-icon {
            position: absolute;
            bottom: 1rem;
            right: 1rem;
            font-size: 1.8rem;
            cursor: pointer;
        }

        .pick-emoji-window.hide {
            display: none;
        }

        .chat-message-input {
            background-color: #e8e9e8;
            font-size: 1.4rem;
            // height: 4.5rem !important;
            // padding: 0 0.75rem;
            // padding-top: 0.375rem;
            // padding: 2rem 1rem;
            display: block;
            width: 100%;
            font-weight: 400;
            // line-height: 1.5;
            color: #495057;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            padding: 1rem;

            &::placeholder {
                display: flex;
                align-items: center;
            }

            &:focus {
                color: #495057;
                border-color: #404040;
                outline: 0;
                box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
            }
        }
    }

    // background: black;
    // margin-left: 2rem;
    display: flex;
    flex: 1;
    justify-content: center;

    .chat-message-text {
        .dark-theme & {
            color: $primary_white;
        }
    }
}

@media screen and (max-width: 1024px) {
    #bpmlive {
        width: 96% !important;
    }

    .video-split-view {
        flex-direction: column;
        margin-left: 0;

        .chat-container {
            max-width: unset;
        }
    }
}

.vod-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 20px;

    .video-container {
        overflow: hidden;
        height: 0;
        padding-top: 56.25%;
        position: relative;

        .vod-bpm,
        .vod-youtube,
        .vod-overlay {
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .vod-overlay {
            z-index: 4;
        }
    }

    .vod-title-share {
        margin-top: 8px;
        display: flex;
        flex-direction: row;

        .vod-title {
            word-break: break-word;
            max-height: calc(21px * 3);
            height: calc(21px * 3);
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            /* number of lines to show */
        }

        .vod-share {
            margin-top: 8px;
            margin-left: auto;
        }
    }
}

@media screen and (max-width: 1024px) {
    .vod-container {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 675px) {
    .vod-container {
        grid-template-columns: 1fr 1fr;
    }
}

.featured-vod-container {
    display: flex;
    flex-direction: row;

    .featured-vod-text-container {
        margin-left: 20px;
        max-width: 400px;
        display: flex;
        flex-direction: column;

        .featured-vod-text-title {
            font-weight: 600;
            font-size: 1.1rem;
        }

        .featured-vod-text-description {
            margin-top: 20px;
        }

        .featured-vod-text-uploaded {
            margin-top: auto;
            justify-self: flex-end;
        }

        .featured-vod-share {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;

            .browse-dropdown {
                width: auto;
            }
        }
    }

    .vod-youtube {
        iframe {
            max-width: 100% !important;
        }
    }
}

@media screen and (max-width: 875px) {
    .featured-vod-container {
        flex-direction: column;
    }
}

$primary: #1af085;