/*** box sizing border-box for all elements ***/
*,
*::before,
*::after {
    box-sizing: border-box;
}
* {
    @media (hover: none) and (pointer: coarse) {
        &:hover {
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }   
    }
}
html {
    font-size: 9px;
}
body {
    font-size: 14px;
    margin: 0;
    padding: 0;
}
body,
#root {
    min-height: 100vh;
}
a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}
button {
    background-color: transparent;
    color: inherit;
    border-width: 0;
    padding: 0;
    cursor: pointer;
}
figure {
    margin: 0;
}
input::-moz-focus-inner {
    border: 0;
    padding: 0;
    margin: 0;
}
ul,
ol,
dd {
    margin: 0;
    padding: 0;
    list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-size: inherit;
    font-weight: inherit;
}
p,
hr {
    margin: 0;
}
cite {
    font-style: normal;
}
fieldset {
    border-width: 0;
    padding: 0;
    margin: 0;
}

a:focus,
button:focus,
input:focus,
textarea:focus {
    outline: none;
}

input[type='search']::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
}

input[type='checkbox'] {
    margin: 0;
}

input:matches([type='radio'], [type='checkbox']) {
    margin-top: 0;
    margin-bottom: 0;
}

.MuiTooltip-tooltip {
    font-size: 1rem !important;
}