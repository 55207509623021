.Contest-Header {
    display: flex;
    padding: 30px;
    @media all and (max-width: 580px) {
        flex-direction: column;
        align-items: center;
    }
    .artwork {
        width: 210px;
        height: 210px;
        margin-bottom: 20px;
        border-radius: 4px;
        @media all and (min-width: 580px) {
            margin-right: 40px;
            margin-bottom: 0;
        }
    }
    .right-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        @media all and (max-width: 580px) {
            align-items: center;
        }
        .title {
            color: #fff;
            font-size: 26px;
            text-transform: uppercase;
            font-family: 'Poppins-semi-bold';
            margin-bottom: 15px;
            margin-top: 15px;
            @media all and (max-width: 1600px) {
                font-size: 22px;
            }
            @media all and (max-width: 835px) {
                font-size: 22px;
            }
            @media all and (max-width: 580px) {
                text-align: center;
            }
        }
        .artist {
            display: none;
            color: #fff;
            font-size: 14px;
            font-size: 15px;
            // margin-bottom: 25px;
            @media all and (max-width: 580px) {
                text-align: center;
            }
        }
        .big-buttons {
            display: flex;
            margin-bottom: 25px;
            flex-wrap: wrap;
            justify-content: start;
            margin-left: -1rem;
            @media all and (max-width: 580px) {
                justify-content: center;
            }
            .BPM-Button {
                margin: 1rem;
                border: 1px solid #fff;
                justify-content: flex-start;
                width: 234px;
                height: 54px;
                display: flex;
                justify-content: center;
                &:last-child {
                    img {
                        filter: invert(14%) sepia(95%) saturate(7481%) hue-rotate(333deg) brightness(85%) contrast(102%);
                    }
                }
            }
        }
        .share-container {
            display: flex;
            align-items: center;
            .share-text {
                color: rgba(#fff, 0.6);
                font-size: 15px;
                margin-right: 10px;
                @media all and (max-width: 580px) {
                    display: none;
                }
            }
            .share-button {
                display: flex;
                width: 25px;
                img {
                    width: 25px;
                }
                cursor: pointer;
                &:not(:last-child) {
                    margin-right: 10px;
                }
            }
            .terms {
                color: white;
                margin-left: auto;
                margin-right: 10px;
                font-size: 1.6rem;
            }
        }
    }
}
