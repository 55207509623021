$player-height: 70px !default;
$player-height-small: 60px !default;

.close {
    cursor: pointer;
}

#track-info {
    padding-left: 0;
    padding-right: 0;
    width: 300px;
    left: 10px !important;
    background-color: #000;
    opacity: 1;
    span {
        &.track-info-title {
            color: #96959c;
            display: block;
            float: left;
            width: 50%;
        }
        &.track-info-text {
            color: $white;
            float: left;
            display: block;
            width: 50%;
        }
    }
    li {
        list-style: none;
        width: 100%;

        display: block;
    }
    ul {
        width: 100%;
        display: block;
    }
}

.audio-player {
    position: relative;
    height: $player-height;
    padding: 0 5rem;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000;
    z-index: 200;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .light-theme & {
        background: $white;
        border-top: 1px solid #e6e6e6;
    }
    @media all and (max-width: 1600px) {
        padding: 0 3rem 0 2rem;
    }
    @media screen and (max-width: $bpm-brk-6) {
        height: $player-height-small;
    }
    transition: height 0.2s ease-out;

    .left-container {
        display: flex;
        align-items: center;
        z-index: 99;
    }

    .middle-container {
        position: absolute;
        left: 53.5%;
        display: flex;
        transform: translateX(-50%);
        @media all and (max-width: 1600px) {
            width: 500px;
        }
        @media all and (max-width: $bpm-player-brk) {
            height: 100%;
        }
    }
    .right-container {
        position: relative;
    }
    &.hidden {
        height: 0;
        overflow: hidden;
    }

    .no-touch & {
        .opacity-control {
            opacity: 0.5;
            &:hover {
                opacity: 1;
            }
        }
    }
    .my-auto {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }
    .col {
        &.artwork {
            display: flex;
            flex-direction: column;
            min-width: 0;
            height: 100%;
            justify-content: center;
            font-size: 1.6rem;
            cursor: pointer;
            .artwork {
                width: 4.5rem;
                height: auto;
                margin-right: 2.5rem;
            }
            @media all and (max-width: $bpm-brk-3) {
                display: none;
            }
        }
        &.label-title {
            display: flex;
            flex-direction: column;
            min-width: 0;
            height: 100%;
            justify-content: center;
            font-size: 1.6rem;
            @media screen and (max-width: $bpm-brk-6) {
                font-size: 1.3rem;
            }
            > div.title:hover {
                text-decoration: underline;
                cursor: pointer;
            }
            .title {
                color: $white;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                @media all and (max-width: 1920px) {
                    max-width: 38rem;
                }

                cursor: pointer;
                .light-theme & {
                    color: $black;
                }
                @media all and (max-width: $bpm-brk-2) {
                    max-width: 22rem;
                }
                @media screen and (max-width: $bpm-brk-5) {
                    max-width: 16rem;
                }
                @media screen and (max-width: $bpm-brk-6) {
                    max-width: 12rem;
                }
            }
            .label {
                font-size: 1.4rem;
                color: $grey;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                cursor: pointer;
                .light-theme & {
                    color: #939393;
                }
                &:hover {
                    text-decoration: underline;
                }
                @media screen and (max-width: $bpm-brk-6) {
                    font-size: 1.2rem;
                }
            }
            .link:hover {
                .no-touch & {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
        &.version {
            .version-container {
                width: 120px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $primary;
                color: $white;
                font-size: 1.2rem;
            }
            @media screen and (max-width: $bpm-brk-6) {
                display: none;
            }
        }
        &.controls {
            display: flex;
            flex-direction: row;
            grid-column-start: 3;

            @media screen and (max-width: $bpm-player-brk) {
                position: absolute;
                left: 50%;
                display: flex;
                transform: translateX(-50%);
                top: 0;
                height: 100%;
            }
            > div {
                cursor: pointer;
                &.opacity {
                    opacity: 0.7;
                }
            }
            > div:first-child {
                margin-right: 8px;
            }
            > div:last-child {
                margin-left: 8px;
            }
            > .play {
                height: 35px;
                width: 35px;
                line-height: 50px;
                margin-right: 8px;
                cursor: pointer;
                @media screen and (max-width: $bpm-brk-6) {
                    height: 35px;
                    width: 35px;
                    border-radius: 20px;
                    line-height: 40px;
                }
                text-align: center;
                vertical-align: middle;
                position: relative;
            }
        }
        &.bpmprogress {
            grid-column-start: 4;
            display: flex;
            overflow: hidden;
            width: 775px;
            .progress-time {
                color: $white;
                .light-theme & {
                    color: $black;
                }
            }
            .duration-time {
                color: $white;
                .light-theme & {
                    color: $black;
                }
            }
            @media screen and (max-width: $bpm-player-brk) {
                display: none;
            }
            > div:not(.wave) {
                flex: 0 0 40px;
                font-size: 1.6rem;
                @media screen and (max-width: $bpm-brk-6) {
                    font-size: 0.7rem;
                }
            }
            > div:not(:first-child) {
                margin-left: 8px; // POS 1
            }
            .wave {
                background-size: 100% 100%;
                background-repeat: no-repeat;
                height: 35px;
                max-width: 100%;
                max-height: $player-height;
                @media screen and (max-width: $bpm-brk-6) {
                    max-height: $player-height-small;
                }
                cursor: pointer;
                .colored-wave {
                    height: 100%;
                    max-width: 100%;
                    width: 0%;
                    overflow: hidden;
                    .colored-wave-inner {
                        height: 100%;
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                    }
                }
            }
        }
        &.settings {
            display: flex;
            flex-direction: row;
            height: 100%;
            background-color: #000;
            display: none;
            @media screen and (max-width: $bpm-brk-6) {
                display: none;
            }
            @media screen and (max-width: $bpm-brk-6) {
                img {
                    height: 20px !important;
                }
            }
            > div {
                display: flex;
                align-items: center;
                height: 100%;
                cursor: pointer;
            }
            > div:not(:first-child) {
                margin-left: 16px;
            }
            .queue {
                img {
                    margin-top: 3px;
                }
            }
            .volume {
                .mobile & {
                    display: none;
                }
                .volume-control {
                    transition: transform 0.3s ease-out;
                    transform: translate(0, 100%);
                    transform-origin: bottom;

                    position: fixed;
                    bottom: $player-height;
                    @media screen and (max-width: $bpm-brk-6) {
                        bottom: $player-height-small;
                    }
                    width: 40px;
                    height: 144px;
                    background-color: #000;
                    z-index: -1;

                    display: flex;
                    flex-direction: row;
                    align-content: center;
                    justify-content: center;
                    > div {
                        position: absolute;
                        height: 114px;
                        bottom: 10px;
                    }
                    .rc-slider {
                        margin-right: 5px;
                    }
                    .rc-slider-rail {
                        width: 10px !important;
                    }
                    .rc-slider-track {
                        background-color: $primary;
                        width: 10px !important;
                    }
                    .rc-slider-handle {
                        border-color: #fff !important;
                        width: 20px !important;
                        height: 20px !important;
                        &:focus {
                            box-shadow: 0 0 0 0 $primary;
                        }
                    }
                }
                &:hover {
                    .volume-control {
                        transform: translate(0, 0);
                    }
                }
            }
        }
    }
}
.hq-popup {
    transition: transform 0.3s ease-out;
    transform: translate(0, 100%);
    transform-origin: bottom;

    position: fixed;
    width: 400px;
    background-color: #000;
    z-index: 197;

    padding: 20px;
    .row {
        display: flex;
        flex-direction: row;
        vertical-align: middle;
    }
    .hq-text {
        color: $white;
        font-size: 0.9rem;
        line-height: 38px;
        margin-left: 20px;
    }
    .hq-toggle {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: 20px;
    }
}

.lead-wave-overlay:after {
    width: var(--overlay-width);
    position: absolute;
    height: 100%;
    top: 0;
    left: calc(100% - var(--overlay-width));
    background-color: rgba(42, 41, 47, 0.58);
    content: '';
}
