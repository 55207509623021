// TODO: Clean up the scss
.Sidebar {
    min-height: 100%;
    max-height: 100%;
    font-size: 1.8rem;
    padding-top: 68px; // padding 1
    z-index: 99;
    padding-bottom: 5rem; // padding 2
    &.closed-sidebar {
        cursor: pointer;
        .deleteDrive {
            display: none;
        }
    }
    &.container {
        min-width: 22rem;
        width: $sidebar-width;
        background-color: $sidebar-background-grey;
        background-color: $background-light-grey;
        position: fixed;
        .light-theme & {
            background-color: lighten(#f6f6f6, 2.4%);
        }
    }

    nav {
        position: fixed;
        overflow: scroll;
        width: 100%;
        max-height: calc(calc(100% - 68px) - 5rem); // padding 1 + padding 2
        margin-top: 2.5rem;
    }

    nav ul {
        #browse-header {
            display: none;
        }
        .my-sounds {
            li.item-container {
                padding: 0.8rem 0 0.8rem 3rem;
            }
        }
        position: relative;
        li.item-container {
            position: relative;
            display: flex;
            align-items: center;
            color: $sidebar-text-grey;
            padding: 0.2rem 0 0.2rem 3rem;
            cursor: pointer;
            max-width: 25rem;
            .icon {
                height: 3.1rem;
                margin-top: 0.3rem;
                display: none;
                .light-theme & {
                    filter: $blue-grey-filter;
                }
            }

            .text {
                // padding-left: 2rem;
                padding-top: 0.8rem;
                font-size: 1.5rem;
                .light-theme & {
                    color: map-get($light-theme, text-grey);
                }
            }

            &:first-child {
                margin-top: 2.8rem;
            }

            &:hover:not(.add-drive),
            &.selected {
                .text {
                    color: $white;
                    .light-theme & {
                        color: map-get($light-theme, black);
                        font-weight: 600;
                    }
                }
                .icon {
                    filter: $crimson-bright-filter;
                    .light-theme & {
                        filter: $crimson-bright-filter !important;
                    }
                }
            }

            // Left Selector Bar
            &:hover:not(.add-drive):before,
            &.selected:not(.add-drive):before {
                position: absolute;
                content: '';
                width: 0.3rem;
                height: 3rem;
                background-color: $primary;
                left: 0.02rem;
                opacity: 1;
                transition: opacity 0.1s;
                top: 0.4rem;
            }
            .deleteDrive {
                padding-top: 0.8rem;
                margin-left: auto;
                margin-right: 2.5rem;
                width: 1.4rem;
                filter: $crimson-bright-filter;
            }
            &:not(:hover) {
                .deleteDrive {
                    display: none;
                }
            }

            // Drive List Items
            &.drive {
                padding: 0.2rem 0 0.2rem 3rem;
                &.drop {
                    .text {
                        color: white;
                    }
                    background-color: $primary;
                }
            }

            // Add Drive Container
            &.add-drive {
                padding: 0;
                display: flex;
                flex-direction: column-reverse;
                margin-top: 0rem;
                align-items: flex-start;
                line-height: 0;
                margin-bottom: 1.5rem;
                margin-left: 1rem;

                .text {
                    color: $primary;
                    font-size: 1.6rem;
                    padding-left: 0;
                    padding-top: 0;
                }
                .buttons-container {
                    margin-top: 1.5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    .BPM-Button {
                        padding: unset;
                        height: 27px;
                        width: 65px;
                        border-radius: 2rem;
                        border: unset;
                        margin-left: 2rem;
                    }
                }
                .add-drive-popup {
                    bottom: 0.3rem;
                    left: 20rem;
                    display: flex;
                    align-items: center;
                    padding: 0 2rem;
                    .light-theme & {
                        background: $white;
                        border: 1px solid #e1e1e1;
                    }
                    &.hidden {
                        display: none;
                    }
                    .close {
                        width: 4rem;
                        padding: 1rem;
                        position: absolute;
                        top: 2rem;
                        right: 2rem;
                        filter: $crimson-bright-filter;
                        display: none;
                    }
                    .pop-text {
                        color: #fff;
                        .light-theme & {
                            color: $sidebar-text-grey;
                        }
                    }
                    input[type='text'] {
                        background-color: #efefef;
                        padding: 0 1rem;
                        border: none;
                        color: #000;
                        display: block;
                        font-size: 1.6rem;
                        height: 3.3rem;
                        outline: none;
                        width: 100%;
                        transition: all 0.2s linear;
                        .light-theme & {
                            color: #555555;
                            background: $white;
                        }
                    }
                    input::placeholder {
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
    }

    .list-section-header {
        @extend .item-container;
        color: $white;
        margin-top: 4.4rem;
        margin-left: 3rem;
        margin-bottom: 0.5rem;
        font-size: 1.5rem;
        &:before {
            display: none;
        }
        .light-theme & {
            color: map-get($light-theme, black);
            font-weight: 600;
        }
    }

    .drive-container {
        display: flex;
        align-items: center;
        margin-left: 2.5rem;
        margin-top: 2.5rem;
        transform: scale(0.95);
        &.enter-drive {
            margin-left: 0;
            justify-content: center;
            .icon {
                display: none;
            }
            .item-container.add-drive {
                margin-left: 0;
            }
        }
    }
}

@media screen and (max-width: $bpm-brk-3) {
    .Sidebar {
        display: none;
    }
}
