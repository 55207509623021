.Submit {
    display: flex;
    margin-bottom: -15rem !important;
    min-height: 100vh;
    margin-top: (-$header-height) !important;
    padding-top: $header-height;

    @media all and (min-width: 1800px) {
        position: absolute;
        left: $sidebar-width;
        margin: 0 !important;
        top: 0;
        width: calc(100vw - #{$sidebar-width});
    }

    @media all and (min-width: $bpm-brk-3) {
        margin: 0 -6rem;
    }

    .input-error {
        color: $primary;
        margin-top: 0.5rem;
    }

    textarea {
        resize: none;
        border-radius: 5px;
        background: transparent;
        border: 1px solid rgba(#fff, 0.1);
        padding: 10px;
        color: #fff;
        height: 80px;
        width: 100%;
        font-size: 14px;

        .light-theme & {
            border: 1px solid #e1e1e1;
            color: $black;
        }
    }

    .profile-container {
        display: flex;
        flex-direction: column;
        width: 467px;
        padding: 0 2rem;

        @media all and (max-width: 500px) {
            width: 100%;
        }

        .picture {
            position: relative;
            margin-top: 1rem;
            margin-bottom: 5rem;
        }

        .edit-icon {
            position: absolute;
            width: 25px;
            bottom: 0.7rem;
            left: 6.2rem;
            cursor: pointer;
            pointer-events: none;
            padding-top: $header-height;
        }

        .profile-image {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            object-fit: cover;
            background: $black;

            .light-theme & {
                background: rgba(234, 234, 234, 0.6);
            }

            &:hover {
                cursor: pointer;
            }
        }
    }

    .input {
        height: 47px;
        border-radius: 6px;
        background-color: $white;
        // .day-mode & {
        //     background-color: map-get($map: $theme-colors, $key: 'DAY_MODE_GREY');
        // }
        font-size: 0.875rem;
        color: $black;
        border: none;
        padding: 0px 16px;
        max-width: 140px;
        display: flex;
        flex: 1;
    }

    // General header text, for forms and FAQ.
    .header {
        color: $white;
        font-size: 2rem;
        font-family: 'Poppins-semi-bold';
        margin-bottom: 2rem;

        &.changepw {
            margin-top: 6.5rem;

            .light-theme & {
                color: $black;
            }
        }

        .light-theme & {
            color: $black;
        }
    }

    input {
        background: transparent;
        border: 1px solid #343434;
        border-radius: 0.7rem;
        padding: 1rem 2rem;
        width: 100%;
        height: 5rem;
        color: white;
        font-size: 1.4rem;

        .light-theme & {
            color: $black;
            border: 1px solid #e1e1e1;
        }
    }

    .fake-input {
        background: transparent;
        border: 1px solid #343434;
        border-radius: 0.7rem;
        padding: 1rem 2rem;
        width: 100%;
        height: 5rem;
        color: white;
        font-size: 1.4rem;
        display: flex;
        align-items: center;
        @extend .no-select;

        span {
            overflow-x: scroll;
            white-space: nowrap;
        }

        @media all and (max-width: 500px) {
            width: 70%;
        }

        .light-theme & {
            color: $black;
            border: 1px solid #e1e1e1;
        }
    }

    .dropdown-wrapper {
        cursor: pointer;
        position: relative;

        .dropdown {
            position: absolute;
        }

        .dropdown-input {
            @extend .fake-input;
            width: 100%;
            padding-right: 5rem;

            span {
                overflow-x: scroll;
                white-space: nowrap;
            }
        }

        .chevron {
            filter: $night-theme-text-filter;
            position: absolute;
            right: 2rem;
            top: 2rem;
        }

        .menu {
            max-width: unset;

            li {
                padding: 1rem 3rem 1rem 3rem;
            }
        }
    }

    .text-label {
        color: map-get($light-theme, text-grey);
        margin-top: 2rem;
        margin-bottom: 1rem;
        font-size: 1.6rem;
    }

    .left {
        position: relative;
        padding: 5rem;
        padding-top: 8.5rem;
        width: 28%;
        background-size: cover;
        background-position: center;

        background-color: rgba(0, 0, 0, 0.4);
        background-blend-mode: darken;

        @media all and (max-width: $bpm-brk-3) {
            display: none;
        }

        .header-main {
            font-size: 5rem;
            color: $white;
            line-height: 6.5rem;

            @media all and (max-width: $bpm-brk-3) {
                font-size: 3.5rem;
            }
        }

        .subtext-main {
            font-size: 2rem;
            color: rgba(#fff, 0.5);
            padding-right: 6rem;

            @media all and (max-width: $bpm-brk-1) {
                padding-right: 0;
            }
        }
    }

    .right {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        .header-main {
            font-size: 5rem;
            color: $white;
            text-align: center;

            @media all and (max-width: $bpm-brk-3) {
                font-size: 3.5rem;
            }

            @media all and (max-width: $bpm-brk-6) {
                font-size: 2.7rem;
                text-align: left;
            }

            @media all and (min-width: $bpm-brk-3) {
                display: none;
            }
        }

        .subtext-main {
            font-size: 2rem;
            color: rgba(#fff, 0.5);
            padding-right: 6rem;
            margin-bottom: 5rem;
            text-align: center;

            @media all and (max-width: $bpm-brk-1) {
                padding-right: 0;
            }

            @media all and (max-width: $bpm-brk-6) {
                font-size: 1.9rem;
                text-align: left;
            }

            @media all and (min-width: $bpm-brk-3) {
                display: none;
            }
        }

        .content {
            padding: 5rem;

            @media all and (max-width: $bpm-brk-4) {
                width: 100%;
            }

            @media all and (max-width: $bpm-brk-6) {
                padding: 2rem;
            }

            .title {
                color: $white;
                font-size: 18px;

                .light-theme & {
                    color: $black;
                }
            }

            .subtext {
                color: map-get($light-theme, text-grey);
                font-size: 1.6rem;
                margin-top: 1rem;
                margin-bottom: 3rem;
            }
        }

        .light-theme & {
            background: $white;
        }

        form {
            display: flex;
            flex-direction: column;
            width: 520px;

            @media all and (max-width: $bpm-brk-4) {
                width: 100%;
            }
        }

        .select-btn {
            background: $black;
            padding: 0 24px;
            color: $primary;
            font-size: 1.6rem;
            border-radius: 5px;
            height: 45px;
            margin-left: 1rem;
            display: flex;
            align-items: center;
            cursor: pointer;

            .light-theme & {
                background: #1af085 !important;
                color: $white;
                transition: background-color 0.15s, color 0.15s ease-in-out;
                // &:hover {
                //     background-color: #ca1241;
                //     color: #fff;
                // }
            }
        }

        .save-btn {
            margin-top: 3rem;
            margin-right: auto;
            background-color: #1af085;
            border-radius: 5px;
            border: none;
            color: #fff;
            cursor: pointer;
            display: inline-block;
            font-size: 1.8rem;
            height: 51px;
            line-height: 51px;
            outline: none;
            overflow: hidden;
            padding: 0 24px;
            min-width: 112px;
            text-decoration: none;
            transition: background-color 0.15s, color 0.15s ease-in-out;

            &:hover {
                background-color: #ca1241;
                color: #fff;
            }
        }
    }

    ::-webkit-scrollbar {
        width: 0;
        height: 0;
        background: transparent;
        /* make scrollbar transparent */
    }

    #preloader {
        display: flex;
        justify-content: center;
    }
}