.DropdownMenu {
    display: inline-block;
    position: relative;
    will-change: filter;
    &.reverse {
        .menu {
            .item {
                padding: 1rem 3rem 1rem 5rem;
            }
        }

        .chevron {
            left: 1.8rem;
            transform: rotate(180deg);
            right: unset;
        }
    }
    .menu {
        background: $black;
        max-width: 19.2rem;
        border-radius: 0.4rem;
        padding: 2rem 0;
        z-index: 9999;
        .light-theme & {
            background: white;
            border: $border-grey;
        }
        .submenu.force-focus {
            visibility: visible !important;
        }
        .item {
            position: relative;
            padding: 1rem 5rem 1rem 3rem;
            color: $night-theme-text;
            font-size: 1.4rem;

            .light-theme & {
                color: map-get($light-theme, text-grey);
            }
            cursor: pointer;
            .chevron {
                position: absolute;
                right: 1.8rem;
                top: 1.6rem;
                width: 0.7rem;
                filter: brightness(0) saturate(100%) invert(50%) sepia(0%) saturate(30%) hue-rotate(163deg)
                    brightness(92%) contrast(86%);
                .light-theme & {
                    filter: brightness(0) saturate(100%) invert(50%) sepia(0%) saturate(30%) hue-rotate(163deg)
                        brightness(92%) contrast(86%);
                }
            }
            &:hover,
            &:active,
            &:focus {
                color: $white;
                .light-theme & {
                    color: map-get($light-theme, black);
                }
                .submenu {
                    visibility: visible;
                }
                .chevron {
                    filter: $white-filter;
                    .light-theme & {
                        filter: $black-filter;
                    }
                }
            }
            .submenu {
                @extend .menu;
                position: absolute;
                top: -2rem;
                visibility: hidden;
                width: 100%;
                padding: 2rem 0;

                .item {
                    font-size: 1.4rem;
                    .light-theme & {
                        color: map-get($light-theme, text-grey);
                    }
                    padding-right: 4rem;
                    @media (hover: hover) and (pointer: fine) {
                        &:hover,
                        &:active,
                        &:focus {
                            color: $white;

                            .light-theme & {
                                color: map-get($light-theme, black);
                            }
                        }
                    }
                }
            }
        }
    }
}

.MuiPaper-root,
.MuiPaper-elevation8 {
    box-shadow: unset !important;
}
