.Carousel {
    position: relative;
    &:hover {
        .left-button {
            visibility: unset;
        }
    }
    .indicator-wrapper {
        width: 20%;
        max-width: 9rem;
    }
    .indicator {
        background: $primary;
        width: 100%;
        height: 2px;
        display: inline-block;
        border-radius: 10rem;
        &.unselected {
            background: #403e3e;
            width: 100%;
            height: 2px;
            display: inline-block;
            border-radius: 10rem;
            .light-theme & {
                background: #ddd;
            }
        }
    }
    .react-multi-carousel-dot-list {
        position: unset;
        display: flex;
        justify-content: center;
    }
    .left-button {
        position: absolute;
        z-index: 2;
        background: $black;
        border-radius: 10rem;
        padding: 2.2rem;
        left: 3rem;
        top: 50%;
        transform: translateY(-50%);
        visibility: hidden;
    }
    .right-button {
        @extend .left-button;
        left: unset;
        right: 3rem !important;
    }

    .left-arrow {
        filter: $white-filter;
        position: absolute;
        width: 1.4rem;
        left: 32%;
        top: 35%;
        filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(253deg) brightness(102%) contrast(101%);
    }
    .right-arrow {
        @extend .left-arrow;
        left: 37%;
        transform: rotate(180deg);
    }
    .items-container {
        margin-right: 4rem;
        @media all and (max-width: 650px) {
            margin-right: 2rem;
        }
        &.link {
            cursor: pointer;
        }
    }
    &.single {
        .items-container {
            margin-right: 0;
        }
    }
    img,
    video {
        width: 100%;
        display: inline-block;
        pointer-events: none;
    }
}
