.BPM-Button {
    padding: 1.5rem 3rem;
    // color: $night-theme-text;
    // background-color: #1d1f1f;
    color: $white;
    background: $primary;

    font-family: Calibri;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.3rem;
    margin: 0;
    white-space: nowrap;

    &.enable-border {
        border: 1px solid rgba(110, 110, 110, 0.34);
        .dark-theme & {
            &:hover {
                border: 1px solid #6e6e6e;
                color: $white;
            }
        }
    }

    .right-text {
        color: $night-theme-text;
    }
    &.selected {
        background-color: $primary;
        color: $white;
    }
    &:disabled {
        background: rgb(36, 36, 36);
        color: rgb(77, 77, 77);
        // border: 1px solid #393939;
        cursor: unset;
    }
    img {
        margin-right: 2rem;
    }
    &:not([disabled]) {
        .btn-text {
            cursor: pointer;
        }
    }

    &.hoverEnabled {
        transition: all 0.15s ease-in-out;
        &:hover {
            // background: #ca1241 !important;
            background: rgba($primary, 0.85) !important;
            color: #fff;
            transition: all 0.15s ease-in-out;
        }
    }

    //^ Button Variations
    &.tag {
        padding: 0.5rem 0.9rem;
        background: #1d1f1f;
        color: $night-theme-text;
        font-size: 1.1rem;
        .light-theme & {
            background: none;
            color: #767676;
        }
    }
    &.tagTiny {
        padding: 0.25rem 0.5rem;
        background: #1d1f1f;
        color: $night-theme-text;
        font-size: 1.1rem;
        .light-theme & {
            background: none;
            color: #767676;
        }
    }
}

:root {
    --balloon-color: blue;
}

.tooltip-red {
    --balloon-color: red;
}
