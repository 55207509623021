//Button Sizes
:root {
    --buttonDefault: '1.5rem 3rem';
    --tagSmall: '.5rem .9rem';
    --tagTiny: '0.25rem 0.5rem';
}

// Fonts
@font-face {
    font-family: 'Poppins';
    src: url('../assets/font/Poppins-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-semi-bold';
    src: url('../assets/font/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-bold';
    src: url('../assets/font/Poppins-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-light';
    src: url('../assets/font/Poppins-Light.ttf') format('truetype');
}

// TODO: Leave for now, in-case we need these at a later time..
@font-face {
    font-family: 'Montserrat';
    src: url('../assets/font/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat-semi-bold';
    src: url('../assets/font/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat-bold';
    src: url('../assets/font/Montserrat-Bold.ttf') format('truetype');
}

// General, dark theme
$primary: #c12ee5 !default;
$black: black;
$white: white;
$grey: #555555;
$dark-grey: #1c1c1c;

$border-grey: 1px solid rgba(110, 110, 110, 0.34);
$night-theme-text: #767676;

$background-light-grey: #1a1a1a; // Formerly #222222
$search-grey: #3b3b3b;

// Button specific
$button-dark-grey: #1f1f1f;
$button-text-grey: #949494;
$button-background-grey: #343434;
$button-highlight-grey: 1px solid rgba(110, 110, 110, 1);

$sidebar-background-grey: #141313;
$sidebar-text-grey: #565656;

// Variables for layout
$header-height: 68px; // px to fix bold line issue on larger displays
$sidebar-width: 22rem;
$sidebar-collapsed-width: 3.5rem;
$sidebar-transition-duration: 0.1s;
$max-width: 1800px;

// Breakpoints
$bpm-brk-1: 1500px !default;
$bpm-brk-2: 1380px !default;
$bpm-brk-3: 1024px !default;
$bpm-brk-4: 800px !default;
$bpm-brk-5: 650px !default;
$bpm-brk-6: 400px !default;

$bpm-player-brk: 850px !default;

$mac-15: 1471px !default;
$ipad-pro-13: 1366px !default;
$ipad-pro-10: 1112px !default;

$keyboard-button-outline: #444444;

// Light theme
$light-theme: (
    black: $black,
    // Used to be dark navy color
    text-grey: #767676,
    divider-grey: #d8d8d8,
    text-red: #dc3e32,
    white: #fff,
    off-white-primary: #f8f8f8,
    // background
    off-white-secondary: #fcfafa,
    // borders
    light-grey-primary: #ece9eb,
    // borders
    light-grey-secondary: #e4e4e4,
    // border bottom
    light-grey-border: #d4cdd1,
    button-red-gradient: $primary,
    baby-blue: #f2f2f2,
    // Now grey
    blue-grey: #a7b2c4,
    blue-grey-light: #e4ebf5,
);

// Box Shadow
$white-box-shadow: 0px 0px 34px 5px rgba(205, 217, 235, 0.56);
$blue-box-shadow: 0px 0px 34px 5px rgba(#cfe0f8, 0.5);

// Filters
$white-filter: brightness(0) saturate(100%) invert(98%) sepia(3%) saturate(300%) hue-rotate(320deg) brightness(120%)
    contrast(100%);
$black-filter: brightness(0%);
$crimson-bright-filter: brightness(0) saturate(100%) invert(15%) sepia(94%) saturate(6041%) hue-rotate(326deg)
    brightness(85%) contrast(135%) !default;

$blue-grey-filter: brightness(0) saturate(100%) invert(75%) sepia(16%) saturate(284%) hue-rotate(178deg) brightness(93%)
    contrast(87%) !important;
$navy-filter: brightness(0) saturate(100%) invert(11%) sepia(40%) saturate(1005%) hue-rotate(170deg) brightness(100%)
    contrast(93%);
$night-theme-text-filter: brightness(0) saturate(100%) invert(51%) sepia(14%) saturate(0%) hue-rotate(151deg)
    brightness(90%) contrast(93%);
