.Title {
    align-items: center;
    .container {
        display: flex;
        align-items: center;
        .header {
            color: $white;
            font-size: 2.2rem;
            font-family: 'Poppins-semi-bold';
            display: flex;
            align-items: center;
            .light-theme & {
                color: map-get($light-theme, $black);
            }
        }
        .view-all {
            cursor: pointer;
            margin-left: 1.8rem;
            color: $primary;
            font-size: 1.4rem;
        }
    }
    .subtext {
        font-size: 1.5rem;
        color: $night-theme-text;
        margin-top: 1rem;
    }
}
