.Membership {
    .cards-container {
        display: grid;
        grid-template-columns: repeat(auto-fill, 264px);
        grid-gap: 19px;
        justify-content: center;
        margin: 0 auto;
        max-width: 840px;
    }
    .Title {
        margin: 5rem 0;
        @media all and (max-width: $bpm-brk-4) {
            .container {
                justify-content: center;
            }
        }
    }

    // Styles when sale exists
    .regular-rate-text {
        color: rgba($white, 0.6);
        text-align: center;
        font-size: 18px;
        margin-top: 40px;
        margin-bottom: 20px;
        font-family: 'Poppins-semi-bold';
        .membership {
        }
        .light-theme & {
            color: rgba($black, 0.8);
        }
    }
    .sale-text {
        @extend .regular-rate-text;
        &:not(.sale) {
            display: none;
        }
        margin-top: 0;
    }
}
