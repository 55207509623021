.Subscribe {
    .BPM-Button {
        border: none !important;
    }
    main {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        max-width: 830px;
        margin-top: 40px;
        border-radius: 6px;
        padding: 24px;
        font-size: 1.6rem;
        // border: 1px solid rgba(#e1e1e1, 0.4);
        border: 1px solid #343434;
        @media all and (max-width: $bpm-brk-4) {
            border: none;
        }
        .light-theme & {
            border: 1px solid #e1e1e1;
        }
        input {
            background: transparent;
            border: 1px solid #343434;
            border-radius: 0.7rem;
            padding: 0.6rem 1rem;
            width: 20rem;
            color: white;
            font-size: 1.6rem;
            .light-theme & {
                color: $black;
                border: 1px solid #e1e1e1;
            }
            &.valid {
                -webkit-box-shadow: 0 0 0 0.1rem #388e3c !important;
                box-shadow: 0 0 0 0.1rem #388e3c !important;
                border: 0;
            }
            &.invalid {
                background-color: red !important;
            }
        }
        .upgrade-paymentinfo {
            color: white;
            .light-theme & {
                color: black;
            }
            .blue-text {
                color: $primary;
            }
            .header-row {
                .text {
                    font-size: 1.6rem;
                }
            }
            .content-row {
                &:last-child .content-head {
                    margin-top: 45px;
                }
                .content-head {
                    margin-top: 12px;
                    font-weight: 500;
                    &.right {
                        float: right;
                    }
                }
                .text.margin {
                    margin-top: 4px;
                }
            }
        }
    }
}
.CreditCardInput {
    display: flex;
    @media all and (max-width: $bpm-brk-4) {
        flex-direction: column;
    }

    form {
        height: 100%;
        margin-right: 4rem;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        .one-col {
            input {
                width: 100%;
            }
        }
    }
    .jp-card-container {
        @media all and (max-width: $bpm-brk-4) {
            margin-top: 5rem;
        }
        @media all and (max-width: $bpm-brk-6) {
            display: flex;
            justify-content: center;
        }
        .jp-card-front {
            background: $black;
            .light-theme & {
                background: #e1e1e1;
                border: 1px solid #e1e1e1;
            }
        }
        .jp-card-back {
            background: $black;
            .light-theme & {
                background: #ddd;
            }
        }
        .jp-card-display {
            .light-theme & {
                color: #5e5e5e;
                opacity: 2.5;
            }
        }
    }
}
