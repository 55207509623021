.TagsButtons-grid {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: -1rem;
    .BPM-Button {
        margin-right: 0.5rem;
        margin-top: 1rem;
        .dark-theme & {
            &:hover {
                border: 1px solid #6e6e6e;
                cursor: pointer;
                color: white;
                .right-text {
                    color: $night-theme-text;
                }
            }
        }
    }
    &:disabled {
        background: rgb(36, 36, 36);
        color: rgb(77, 77, 77);
        outline: 1px solid #393939;
        cursor: unset;
    }
}
