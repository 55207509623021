.DateCards {
    display: grid;
    grid-row-gap: 10px;
    grid-column-gap: 25px;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    @media all and (max-width: 780px) {
        grid-column-gap: 10px;
    }
    .card {
        display: flex;
        flex-direction: column;
        border: $border-grey;
        border-radius: 5px;
        padding: 17px 35px;
        display: inline-flex;
        background: $primary !important;
        .light-theme & {
            border: 1px solid #e1e1e1;
        }
        .text {
            color: #ffffff;
            font-size: 13px;
        }
        .date {
            font-family: 'Poppins-semi-bold';
            color: #ffffff;
        }
    }
}
