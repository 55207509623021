.popup {
    z-index: 1700;
    display: inline-block;
    &:not(.show) {
        display: none;
    }
    .popup_inner {
        position: relative;
        background: $black;
        max-width: 500px;
        min-width: 300px;
        max-height: 225px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 7rem;
        border-radius: 0.3em;
        @media screen and (max-width: $bpm-brk-3) {
            padding: 5rem;
        }
        .light-theme & {
            border: 1px solid #e1e1e1;
            background: $white;
        }
        .close {
            position: absolute;
            right: 1.5rem;
            top: 1.5rem;
            filter: $crimson-bright-filter;
        }
        .img {
            display: none;
        }
        .text-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            .title {
                color: $white;
                .light-theme & {
                    color: map-get($light-theme, white);
                }
            }
            .text {
                color: $white;
                text-align: center;
                .light-theme & {
                    color: map-get($light-theme, black);
                }
            }
            .button-div {
                margin-top: 3rem;
                display: flex;
            }
        }
        .BPM-Button {
            padding: 1.5rem 3rem;
            border: none;
            outline: none;
            background-color: $primary;
        }
    }
}
