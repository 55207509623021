.OrderHistory {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    .load-more {
        margin: auto;
        margin-top: 3rem;
        max-width: 118px;
    }
    .AnimateHeight {
        .hidden {
            visibility: hidden;
            position: absolute;
        }
        &:not(.hidden) {
            visibility: visible;
            position: unset;
        }
    }
    .column {
        display: flex;
        flex: 1;
        padding: 25px 0;
        text-overflow: ellipsis;
        @media all and (max-width: $bpm-brk-5) {
            width: 0rem;
            font-size: 12px;
        }

        // @media all and (min-width: $bpm-brk-6) {
        //     overflow: hidden;
        //     white-space: no-wrap;
        // }
        &-1 {
            // @media all and (max-width: $bpm-brk-6) {
            //     flex: 1.5;
            // }
        }
        &-2 {
            flex: 2;
            @media all and (max-width: $bpm-brk-6) {
                max-width: 16rem;
            }
        }
        &-3 {
            flex: 0.4;
            @media all and (max-width: $bpm-brk-5) {
                flex: 0.8;
            }
        }
        &-4 {
            flex: 0.4;
            flex: 0 0 8rem;
            justify-content: center;
            @media all and (max-width: $bpm-brk-4) {
                flex: 0.3;
            }
            @media all and (max-width: $bpm-brk-5) {
                flex: 0.6;
            }
            .PDF {
                cursor: pointer;
                &:hover {
                    color: $primary;
                }
            }
            .divide {
                margin: 0 0.5rem;
            }
            .HTML {
                cursor: pointer;
                &:hover {
                    color: $primary;
                }
            }
        }
    }
    .header {
        display: flex;
        flex-direction: row;
        font-size: 1.3rem;
        // padding-right: 3rem;
        color: $white;
        .column {
            padding: 0;
        }
        // @media all and (max-width: $bpm-brk-6) {
        //     padding-right: 0;
        // }
        .light-theme & {
            color: $sidebar-text-grey;
        }
    }
    .order-row {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #e1e1e1;
        // padding-right: 3rem;
        .column-4 {
            justify-content: center;
        }
        .dark-theme & {
            border-bottom: 1px solid #343434;
            color: rgba($white, 0.3);
            &:hover {
                background: rgba(#232323, 0.25);
            }
        }
        &:hover {
            background: rgba(map-get($light-theme, off-white-primary), 0.7);
        }
        &:last-child {
            border: none;
        }
        // @media all and (max-width: $bpm-brk-6) {
        //     padding-right: 0;
        // }
    }
}
