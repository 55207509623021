.classic-mode-banner {
  &__container {
    position: fixed;
    width: 100%;
    height: 36px;
    background: #151515;
    box-shadow: inset 0 -1px 0 #2a2a2a;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999;
  }

  &__container-text {
    color: #e6f0e9;
    font-size: 13px;
    text-align: center;
    font-weight: 500;
    letter-spacing: .4px;
    flex: 1;
    margin-bottom: 0;

    a {
      color: #fff;

      @media (hover: hover) {
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  &__button {
    margin-right: 20px;
    background: #151515;
    border: 0;
    outline: 0;

    svg {
      pointer-events: none;
    }

    @media (hover: hover) {
      &:hover {
        svg path {
          stroke: white;
        }
      }
    }
  }
}

.isClassicBannerShown .HeaderBar,
.isClassicBannerShown .Sidebar {
  top: 36px;
}

.isClassicBannerShown .main-content {
  padding-top: calc(68px + 36px) !important;
}

.isClassicBannerShown .HamburgerMenu {
  padding-top: 36px;
}